<h1 mat-dialog-title>{{ data.title }}</h1>
<div mat-dialog-content>
  <p [innerHTML]="data.message | safe:'html'"></p>
  @if(data.useConfirmationCode && confirmationCode) {
    <p>Inserisci il codice <strong>{{confirmationCode}}</strong> per procedere con l'eliminazione</p>
    <mat-form-field>
      <input matInput [placeholder]="confirmationCode.toString()" [(ngModel)]="userConfirmationCode">
    </mat-form-field>
  }
</div>
<div align="end" mat-dialog-actions>
  <button [mat-dialog-close]="true" mat-button
          [disabled]="data.useConfirmationCode && (confirmationCode !== userConfirmationCode)">{{ data.confirm ?? 'OK' }}</button>
  <button [mat-dialog-close]="false" color="primary" mat-raised-button>{{ data.deny ?? 'ANNULLA' }}</button>
</div>
