import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'isoToDate'
})
export class IsoToDatePipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): Date | null {
    if (value) {
      return new Date(value.toString());
    }
    return null;
  }

}
