import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, Type} from '@angular/core';
import {FieldTypeConfig, FormlyConfig, FormlyFieldConfig} from '@ngx-formly/core';
import {FieldType, FormlyFieldProps} from '@ngx-formly/material/form-field';


interface TimepickerProps extends FormlyFieldProps {
  timepickerOptions?: Partial<{
    disabled: boolean;
    min: any;
    max: any;
  }>;
}

export interface FormlyInputFieldConfig extends FormlyFieldConfig<TimepickerProps> {
  type: 'timepicker' | Type<TimepickerTypeComponent>;
}

@Component({
  selector: 'nbd-datetimepicker-type',
  templateUrl: './timepicker-type.component.html',
  styleUrls: ['./timepicker-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimepickerTypeComponent extends FieldType<FieldTypeConfig<TimepickerProps>> implements OnInit, OnDestroy {

  // private dateTimeLocalFormat = "y-MM-dd'T'T";
  // private sub = new Subscription();


  constructor(private config: FormlyConfig, private cdRef: ChangeDetectorRef) {
    super();
    // if(!this.field.parsers) {
    //   this.field.parsers = [];
    // }
    // this.field.parsers.push((value: any) => this.fromISOToLocal(value))
  }

  ngOnInit() {
    // if(!this.field.parsers) {
    //   this.field.parsers = [];
    // }
    // this.field.parsers.push(
    //   (value: any) => {
    //     if(value?.length === 25) {
    //       return this.fromISOToLocal(value)
    //     }
    //     return value;
    //   }
    // )
    // //Convert the initial value to datetime-local format
    // if (this.formControl.value) {
    //   // console.log(`Setting ${this.formControl.value} to local`);
    //   this.formControl.setValue(this.fromISOToLocal(this.formControl.value), {emitEvent: false});
    // }
    //
    // // Listen for changes and convert the value back to ISO format
    // this.sub.add(this.formControl.valueChanges.pipe(
    //   debug('DateTimePicker ValueChange'),
    //   pairwise(),
    //   debug('DateTimePicker ValueChange PAIRWISE'),
    //   tap(([prev, curr]) => {
    //     console.log(this.key, prev, curr);
    //     if (curr && (prev !== curr)) {
    //       // console.log(`Converting ${value} to local`);
    //       // console.log(res);
    //       // const res = DateTime.fromFormat(value, this.dateTimeLocalFormat).toISO();
    //       const res = this.fromISOToLocal(curr);
    //       if (res) {
    //         // this.model[this.key as string] = res;
    //         this.formControl.setValue(res);
    //       }
    //     }
    //   })
    // ).subscribe());

  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    // this.sub.unsubscribe();
  }

  // fromISOToLocal(date: string) {
  //   return date ? DateTime.fromISO(date).toFormat(this.dateTimeLocalFormat) : null;
  // }
}
