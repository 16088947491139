<div class="py-4">
  @for (f of field.fieldGroup; track f; let i = $index) {
    <div class="grid grid-cols-[1fr_min-content] gap-2 items-center"
         [ngClass]="props.wrapWithCard ? 'border rounded p-2 mb-2 bg-white mat-elevation-z1' : ''">
      <formly-field [field]="f"></formly-field>
      <button (click)="remove(i)" [disabled]="field.props.disabled" class="self-start"
        color="warn"
        mat-icon-button
        type="button">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  }
  <button (click)="add()" [disabled]="field.props.disabled" color="accent" mat-flat-button type="button">
    <mat-icon>add</mat-icon>
    <span>{{ props.actionLabel }}</span>
  </button>
</div>
