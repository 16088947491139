import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {switchMap, tap} from 'rxjs';
import {AutoUnsubscribe} from '../../shared/classes/AutoUnsubscriber';
import {AuthService} from '../auth.service';

@AutoUnsubscribe()
@Component({
  selector: 'nbd-url-login',
  templateUrl: './url-login.component.html',
  styleUrls: ['./url-login.component.scss']
})
export class UrlLoginComponent {

  constructor(private route: ActivatedRoute, private router: Router, private auth: AuthService) {

    (this as any).sub = this.route.queryParamMap.pipe(
      tap((params) => {
        if (!params.has('h')) {
          this.router.navigate(['/auth/login']);
        }
      }),
      filter((params) => params.has('h')),
      switchMap((params) => this.auth.loginWithUrl({
        token: params.get('h') ?? ''
      })),
      tap(() => this.router.navigate(['/']))
    ).subscribe();

  }

}
