import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {canMatchAuthenticated} from './auth/classes/canMatchAuthenticated';
import {NotFoundComponent} from './core/not-found/not-found.component';

const routes: Routes = [
  {
    path: 'users',
    loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
    canMatch: [canMatchAuthenticated(['admin'])]
  },
  {
    path: 'events',
    loadChildren: () => import('./events/events.module').then(m => m.EventsModule),
    canMatch: [canMatchAuthenticated()]
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
    canMatch: [canMatchAuthenticated()]
  },
  {
    path: 'profile',
    loadChildren: () => import('./user-profile/user-profile.module').then(m => m.UserProfileModule),
    canMatch: [canMatchAuthenticated()]
  },
  {
    path: 'help',
    loadChildren: () => import('./support/support.module').then(m => m.SupportModule),
    canMatch: [canMatchAuthenticated()]
  },
  {
    path: 'payments',
    loadChildren: () => import('./payment/payment.module').then(m => m.PaymentModule),
    canMatch: [canMatchAuthenticated()]
  },
  {
    path: 'user-lists',
    loadChildren: () => import('./user-lists/user-lists.module').then(m => m.UserListsModule),
    canMatch: [canMatchAuthenticated(['admin'])]
  },
  // {
  //   path: 'test',
  //   loadChildren: () => import('./tester/tester.module').then(m => m.TesterModule),
  //   canMatch: [canMatchAuthenticated()]
  // },
  {
    path: 'communications',
    loadChildren: () => import('./communications/communications.module').then(m => m.CommunicationsModule),
    canMatch: [canMatchAuthenticated(['admin'])]
  },
  {
    path: 'form-builder',
    loadChildren: () => import('./form-builder/form-builder.module').then(m => m.FormBuilderModule),
    canMatch: [canMatchAuthenticated(['admin'])]
  },
  {
    path: 'templates',
    loadChildren: () => import('./html-template-editor/html-template-editor.module').then(m => m.HtmlTemplateEditorModule),
    canMatch: [canMatchAuthenticated(['admin'])]
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canMatch: [canMatchAuthenticated()]
  },
  {
    path: 'promo-code',
    loadChildren: () => import('./promo-code/promo-code.module').then(m => m.PromoCodeModule),
    canMatch: [canMatchAuthenticated(['admin'])]
  },
  {
    path: 'crm-log',
    loadChildren: () => import('./crm-log/crm-log.module').then(m => m.CrmLogModule),
    canMatch: [canMatchAuthenticated(['admin'])]
  },
  {
    path: 'legal',
    loadChildren: () => import('./legal/legal.module').then(m => m.LegalModule)
  },
  {
    path: 'not-found', component: NotFoundComponent
  },
  // {
  //   path: 'dev-test',
  //   loadChildren: () => import('./dev-test/dev-test.module').then(m => m.DevTestModule),
  //   canMatch: [canMatchAuthenticated()],
  //   data: {
  //     directNavigation: true
  //   }
  // },
  {path: '', pathMatch: 'full', redirectTo: 'events'},
  {path: '**', component: NotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // enableTracing: true
    paramsInheritanceStrategy: 'always',
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
