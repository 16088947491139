import {Injectable} from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  defer,
  map,
  Observable,
  of,
  share,
  switchMap,
  tap,
  throttleTime,
  timer
} from 'rxjs';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {HelperService} from '../../shared/helper.service';
import {shareReplay} from 'rxjs/operators';
import {RgEvent} from '../classes/rg-event';
import {Guest} from '../classes/guests';
import {debug} from '../../shared/rxjs/debug';
import {ColumnConfig} from '../../shared/table/models/ColumnConfig';
import {RgColumnConfig} from '../classes/rg-column-config';
import {DateTime} from 'luxon';
import {AuthService} from '../../auth/auth.service';
import {notNullOrUndefined} from '../../shared/rxjs/notNullOrUndefined';
import {EventPlan} from '../../payment/classes/EventPlan';
import {PaymentService} from '../../payment/payment.service';
import {Cluster} from '../classes/cluster';
import {JobStatus} from '../classes/job-status';
import {SendQRResult, SendQrType} from '../../qr-manager/classes/send-qr-type';
import {EmailSendJob} from '../../qr-manager/classes/email-send-job';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {RsvpStats} from '../classes/rsvp-stats';

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  events$: Observable<RgEvent[]>;
  clusters$: Observable<Cluster[]>;
  // smsClusters$: Observable<Cluster[]>;
  // emailClusters$: Observable<Cluster[]>;
  emptyCluster$: Observable<Cluster>;
  // smsEmptyCluster$: Observable<Cluster>;
  // emailEmptyCluster$: Observable<Cluster>;
  finishedEvents$: Observable<RgEvent[]>;
  selectedEvent$ = new BehaviorSubject<RgEvent | null>(null);
  selectedPlan$: Observable<EventPlan>;

  // guests$: Observable<Guests>;

  refreshEvents$ = new BehaviorSubject<boolean>(true);
  refreshFinishedEvents$ = new BehaviorSubject<boolean>(true);
  refreshGuests$ = new BehaviorSubject<boolean>(true);
  refreshClusters$ = new BehaviorSubject<boolean>(true);
  refreshCredits$ = new BehaviorSubject<boolean>(true);

  timer$: Observable<DateTime>;

  clustersMap = new Map<string, Cluster>();

  constructor(private http: HttpClient, private hs: HelperService, private auth: AuthService, private ps: PaymentService) {

    this.timer$ = timer(0, 1000).pipe(
      map(() => DateTime.now()),
      share()
    );

    this.events$ = combineLatest([this.refreshEvents$, this.auth.user$.pipe(
      notNullOrUndefined()
    )]).pipe(
      switchMap(() => this.http.get<RgEvent[]>(`${environment.appUrl}/events`).pipe(
        this.hs.httpErrorHandler([]))
      ),
      map((events: RgEvent[]) => {
        events.forEach(e => {
          this.setEventViewData(e);
        });
        return events;
      }),
      debug('Events'),
      tap((event) => {
        if (this.selectedEvent$.value) {
          this.selectedEvent$.next(null)
        }
      }),
      shareReplay(1)
    );

    this.finishedEvents$ = this.refreshFinishedEvents$.pipe(
      switchMap(() => this.http.get<RgEvent[]>(`${environment.appUrl}/events/finished`)),
      this.hs.httpErrorHandler([]),
      shareReplay(1)
    )


    this.selectedPlan$ = this.selectedEvent$.pipe(
      notNullOrUndefined(),
      map((event) => event.eventPlan),
      switchMap((planName) => this.ps.getAvailablePlans().pipe(
        map((plans) => plans.find((p) => p.label === planName))
      )),
      debug('Selected Plan'),
      notNullOrUndefined()
    );

    this.clusters$ = combineLatest([this.refreshClusters$, this.selectedEvent$.pipe(
      notNullOrUndefined()
    )]).pipe(
      switchMap(([_, event]) => this.getClusters(event.id)),
      tap((clusters) => {

        this.clustersMap.clear();
        clusters.forEach((cluster) => {
          if (cluster.id) {
            this.clustersMap.set(cluster.id, cluster);
          } else {
            this.clustersMap.set('empty', {
              id: 'empty',
              name: `Non assegnati`,
              guestsCount: cluster?.guestsCount ?? 0,
              clusterData: {
                color: '#dddddd',
                icon: '🔘'
              }
            } as Cluster);
          }
        })
      }),
      shareReplay(1)
    );

    this.emptyCluster$ = this.getEmptyCluster(this.clusters$);
    // this.smsEmptyCluster$ = this.getEmptyCluster(this.smsClusters$);
    // this.emailEmptyCluster$ = this.getEmptyCluster(this.emailClusters$);

    // this.guests$ = combineLatest([this.selectedEvent$, this.refreshGuests$]).pipe(
    //   switchMap(([event, _]) => {
    //     if (!event) {
    //       return of([])
    //     }
    //     return this.http.get<Guest[]>(`${environment.appUrl}/events/${event.id}/guests`);
    //   }),
    //   map((guests) => {
    //     if (guests.length > 0) {
    //       return {
    //         cols: Object.keys(guests[0]).length,
    //         items: guests
    //       };
    //     }
    //     return new Guests();
    //   })
    // );

  }

  public getEmptyCluster(clusters: Observable<Cluster[]>) {
    return clusters.pipe(
      map((clusters) => {
        const emptyCluster = clusters.find(c => c.id === null);
        return {
          id: 'empty',
          name: `Non assegnati`,
          guestsCount: emptyCluster?.guestsCount ?? 0,
          clusterData: {
            color: '#dddddd',
            icon: '🔘'
          }
        } as Cluster;
      }),
      share()
    );
  }

  public setEventViewData(e: RgEvent) {
    const fromDateObj = DateTime.fromISO(e.fromDate);
    const toDateObj = DateTime.fromISO(e.toDate);

    e.totalDays = toDateObj.diff(fromDateObj, 'days').days;
    const elapsed = fromDateObj.diffNow(['days', 'hours']);
    e.elapsedDays = Math.max(0, elapsed.days * -1);
    e.elapsedHours = Math.max(0, elapsed.hours * -1);

    const elapsedDays = Math.max(0, elapsed.days);
    const elapsedHours = Math.max(0, elapsed.hours);
    if (elapsedDays > 0) {
      e.timeToEvent = {
        amount: Math.max(0, elapsed.days),
        unit: 'days'
      };
    } else if (elapsedHours >= 1) {
      e.timeToEvent = {
        amount: Math.max(0, elapsed.hours),
        unit: 'hours'
      };
    } else if (elapsedHours > 0 && elapsedHours < 1) {
      e.timeToEvent = {
        amount: 0,
        unit: 'soon'
      };
    } else {
      e.timeToEvent = {
        amount: 0,
        unit: ''
      };
    }

    e.remainingDays = e.totalDays - e.elapsedDays;
    e.running = e.timeToEvent.amount === 0 && !e.timeToEvent.unit && e.remainingDays > 0;
    e.finished = e.remainingDays <= 0;

    return e;
  }

  getEvent(eventId: string | number, updateSelected = true) {
    return this.http.get<RgEvent>(`${environment.appUrl}/events/${eventId}`).pipe(
      this.hs.httpErrorHandler(null),
      notNullOrUndefined(),
      map((event) => this.setEventViewData(event)),
      tap((event) => {
        if (updateSelected) {
          this.selectedEvent$.next(event);
        }

      }),
      debug('Event'),
      shareReplay(1)
    );
  }

  getEventHistogram(granularity: "h" | "m" = "m") {
    return this.timer$.pipe(
      throttleTime(5000),
      debug('**** hist timer'),
      switchMap(() => this.http.get<{
        total: number;
        time: string;
      }[]>(`${environment.appUrl}/events/${this.selectedEvent$.value?.id}/checkin-hist?t=${granularity}`).pipe(
        this.hs.httpErrorHandler([]),
        debug('CheckIn Hist')
      )),
      share()
    );
  }

  getFormConfig(event: RgEvent) {
    return this.http.get<FormlyFieldConfig[]>(`${environment.appUrl}/events/${event.id}/form-config`).pipe(
      this.hs.httpErrorHandler([]),
      debug('FormConfig'),
      shareReplay(1)
    );
  }

  getGuest(eventId: string | number, guestId: string | number) {
    return this.http.get<Guest>(`${environment.appUrl}/events/${eventId}/guests/${guestId}`).pipe(
      this.hs.httpErrorHandler(null),
      // tap((event) => this.selectedEvent$.next(event)),
      debug('Guest'),
      shareReplay(1)
    );
  }

  putGuest(guest: Guest) {
    return this.http.put<Guest>(`${environment.appUrl}/events/${this.selectedEvent$.value?.id}/guests/${guest.id}`, {guest}).pipe(
      this.hs.httpErrorHandler(null),
      // tap((event) => this.selectedEvent$.next(event)),
      debug('PutGuest')
    );
  }

  emptyGuestList() {
    return this.http.delete<any>(`${environment.appUrl}/events/${this.selectedEvent$.value?.id}/guests/all`).pipe(
      this.hs.httpErrorHandler(null),
      // tap((event) => this.selectedEvent$.next(event)),
      debug('DeleteGuests')
    );
  }

  deleteSelected(guests: string[] | number[]) {
    return this.http.post<any>(`${environment.appUrl}/events/${this.selectedEvent$.value?.id}/guests/delete-selected`,
      {guests}).pipe(
      this.hs.httpErrorHandler(null),
      // tap((event) => this.selectedEvent$.next(event)),
      debug('DeleteSelectedGuests')
    );
  }

  assignSelectedToGroup(guests: string[] | number[], clusterId: string | null) {
    return this.http.post<any>(`${environment.appUrl}/events/${this.selectedEvent$.value?.id}/guests/assign-selected-to-group`,
      {guests, clusterId}).pipe(
      this.hs.httpErrorHandler(null),
      // tap((event) => this.selectedEvent$.next(event)),
      debug('AssignGroupToSelectedGuests')
    );
  }

  deleteGuest(guestId: number) {
    return this.http.delete<any>(`${environment.appUrl}/events/${this.selectedEvent$.value?.id}/guests/${guestId}`).pipe(
      this.hs.httpErrorHandler(null),
      // tap((event) => this.selectedEvent$.next(event)),
      debug('DeleteGuest')
    );
  }

  postCluster(cluster: Cluster) {
    return this.http.post<Cluster>(`${environment.appUrl}/events/${this.selectedEvent$.value?.id}/clusters`, cluster).pipe(
      this.hs.httpErrorHandler(null),
      // tap((event) => this.selectedEvent$.next(event)),
      debug('PostCluster')
    );
  }

  putCluster(cluster: Cluster) {
    return this.http.put<Cluster>(`${environment.appUrl}/events/${this.selectedEvent$.value?.id}/clusters/${cluster.id}`, cluster).pipe(
      this.hs.httpErrorHandler(null),
      // tap((event) => this.selectedEvent$.next(event)),
      debug('PutCluster')
    );
  }

  deleteCluster(clusterId: string) {
    return this.http.delete<any>(`${environment.appUrl}/events/${this.selectedEvent$.value?.id}/clusters/${clusterId}`).pipe(
      this.hs.httpErrorHandler(null),
      // tap((event) => this.selectedEvent$.next(event)),
      debug('DeleteCluster')
    );
  }

  getClusters(eventId: string) {
    return this.http.get<Cluster[]>(`${environment.appUrl}/events/${eventId}/clusters`).pipe(
      this.hs.httpErrorHandler([]),
      // tap((event) => this.selectedEvent$.next(event)),
      debug('Clusters'),
      // shareReplay(1)
    );
  }

  getClustersForQr(type: "sms" | "email") {
    return combineLatest([this.refreshClusters$, this.selectedEvent$.pipe(
      notNullOrUndefined()
    )]).pipe(
      switchMap(([_, event]) => this.http.get<Cluster[]>(`${environment.appUrl}/events/${event.id}/qr/qr-clusters/${type}`).pipe(
        this.hs.httpErrorHandler([]),
        debug(`${type}Clusters`),
      )),
      shareReplay(1)
    );

  }

  getQrCodeList() {
    return this.http.get<string[]>(`${environment.appUrl}/events/${this.selectedEvent$.value?.id}/qr/list`).pipe(
      this.hs.httpErrorHandler([]),
      debug(`QRList`),
    );
  }

  sendQr<T extends SendQrType>(type: T, clusters: any[]) {
    return this.http.post<SendQRResult<T>>(`${environment.appUrl}/events/${this.selectedEvent$.value?.id}/qr/send-${type}`, {clusters}).pipe(
      this.hs.httpErrorHandler(null),
      debug(`SendQr${type}`),
    );
  }

  sendRsvp(clusters: any[]) {
    return this.http.post<SendQRResult<'rsvp'>>(`${environment.appUrl}/events/${this.selectedEvent$.value?.id}/rsvp/send`, {clusters}).pipe(
      this.hs.httpErrorHandler(null),
      debug(`SendRsvp`),
    );
  }

  getJobStatus<T extends SendQrType>(type: T, jobId?: string) {
    return this.http.get<JobStatus>(
      `${environment.appUrl}/events/${this.selectedEvent$.value?.id}/jobs/check-status/${type}${jobId ? `/${jobId}` : ''}`).pipe(
      this.hs.httpErrorHandler(null),
      map((res) => {
        if (res && res.status === null) {
          return null;
        }
        return res;
      }),
      debug('JobStatus'),
    );
  }

  getSendJobs<T extends SendQrType>(type: T) {
    return this.http.get<EmailSendJob[]>(
      `${environment.appUrl}/events/${this.selectedEvent$.value?.id}/jobs/${type}`).pipe(
      this.hs.httpErrorHandler([]),
      debug('SendJobs')
    );
  }

  getRsvpStats() {
    return defer(() => this.http.get<RsvpStats[]>(`${environment.appUrl}/events/${this.selectedEvent$.value?.id}/rsvp`).pipe(
      this.hs.httpErrorHandler([]),
      map((stats) => {
        stats.forEach((rsvp) => {
          if (rsvp.clusterId === null) {
            rsvp.clusterId = 'empty';
          }
        });
        return stats;
      }),
      // tap((event) => this.selectedEvent$.next(event)),
      debug('RsvpStats'),
      share()
    ));
  }

  public createOrUpdateItem(item: any, eventId: number) {
    return defer(() => {
      if (eventId === 0) {
        return this.postEvent(item);
      } else {
        return this.putEvent(item, eventId);
      }
    }).pipe(
      this.hs.httpErrorHandler(null)
    );
  }

  postEvent(eventData: any) {
    return this.http.post<RgEvent>(`${environment.appUrl}/events`, eventData).pipe(
      this.hs.httpErrorHandler(null)
    )
  }

  deleteEvent(eventId: string | number) {
    return this.http.delete<any>(`${environment.appUrl}/events/${eventId}`).pipe(
      this.hs.httpErrorHandler(null)
    )
  }

  postCustomFields(eventId: string | number, customFields: RgColumnConfig[]) {
    return this.http.post<any>(`${environment.appUrl}/events/${eventId}/custom-fields`, {customFields}).pipe(
      this.hs.httpErrorHandler(null)
    )
  }

  activateEvent(eventId: string | number) {
    return this.http.post<RgEvent>(`${environment.appUrl}/events/${eventId}/enable`, {}).pipe(
      this.hs.httpErrorHandler(null)
    )
  }

  putEvent(eventData: any, eventId: string | number) {
    return this.http.put<RgEvent>(`${environment.appUrl}/events/${eventId}`, eventData).pipe(
      this.hs.httpErrorHandler(null)
    )
  }

  updateWebsiteConfig() {
    return this.http.put<RgEvent>(`${environment.appUrl}/events/${this.selectedEvent$.value?.id}/website`, {}).pipe(
      this.hs.httpErrorHandler(null)
    )
  }

  uploadGuests(guests: Guest[], cluster: string | null) {
    return this.http.post<any>(`${environment.appUrl}/events/${this.selectedEvent$.value?.id}/guests/import`,
      {
        guests: guests,
        clusterId: cluster
      }
    ).pipe(
      this.hs.httpErrorHandler(null)
    )
  }

  postNewGuest(data: any) {
    return this.http.post<any>(`${environment.appUrl}/events/${this.selectedEvent$.value?.id}/guests`, data).pipe(
      this.hs.httpErrorHandler(null)
    )
  }

  getGuestsTableConfig() {
    return this.http.get<ColumnConfig[]>(`${environment.appUrl}/events/${this.selectedEvent$.value?.id}/table-config`).pipe(
      catchError((err) => of(null)),
      map((res) => res || [])
    );
  }

  export() {
    return this.http.get<{
      fileName: string;
      url: string;
    }>(`${environment.appUrl}/events/${this.selectedEvent$.value?.id}/guests/full-export`).pipe();
  }

  refreshEvents(finished = false) {
    if(finished) {
      this.refreshFinishedEvents$.next(true);
    } else {
      this.refreshEvents$.next(true);
    }
  }

  refreshGuests() {
    this.refreshGuests$.next(true);
  }

  refreshCredits() {
    this.refreshCredits$.next(true);
  }

  refreshClusters() {
    this.refreshClusters$.next(true);
  }


}
