import {CanMatchFn, Router} from '@angular/router';
import {inject} from '@angular/core';
import {AuthService} from '../auth.service';

export function canMatchAuthenticated(roles: string[] = []): CanMatchFn {
  return () => {
    const auth: AuthService = inject(AuthService);
    // const roles = ['admin', 'crm', 'crm-sgr', 'crm-bg'];

    if (auth.user$.value && (roles.length === 0 || roles.includes(auth.user$.value?.role))) {
      return true;
    }

    const router: Router = inject(Router);

    return router.createUrlTree([!!auth.user$.value ? '/not-found' : '/auth/login']);
  };
}
