import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, ReplaySubject, switchMap, tap} from 'rxjs';
import {MenuItem} from './classes/menu-item';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import {debug} from '../shared/rxjs/debug';
import {filter, map} from 'rxjs/operators';
import {LimitedStack} from '../shared/classes/LimitedStack';
import {AuthService} from '../auth/auth.service';
import {HelperService} from '../shared/helper.service';
import {AppMenu} from './classes/app-menu';
import {EventsService} from '../realguest/services/events.service';
import {AccountCreditsData} from './classes/account-credits-data';
import {notNullOrUndefined} from '../shared/rxjs/notNullOrUndefined';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CoreService {

  menuItems$: Observable<AppMenu>;
  userMenu$: Observable<MenuItem[]>;
  currentRoute$ = new ReplaySubject<string>(1);
  userMenuExpanded$ = new BehaviorSubject<boolean>(false);
  collapseMenu$ = new BehaviorSubject<boolean>(false);

  navHistory = new LimitedStack<string>(50);

  accountCreditsData$: Observable<AccountCreditsData>;

  constructor(private router: Router, private auth: AuthService,
              private hs: HelperService, private es: EventsService,
              private http: HttpClient
  ) {
    this.router.events.pipe(
      tap((event) => {
        if (event instanceof NavigationStart) {
          if (this.navHistory.peek() !== event.url) {
            this.hs.toolbarComponent$.next(null);
            this.hs.showBackBtn$.next(false);
          }
        }
      }),
      filter((event) => event instanceof NavigationEnd),
      map((event) => (event as NavigationEnd).urlAfterRedirects),
      tap((route) => {
        if (this.auth.user$.value && route.startsWith('/auth')) {
          this.router.navigate(['/']);
          return;
        }
        if (this.es.selectedEvent$.value) {
          if (!route.startsWith('/events/')) {
            this.es.selectedEvent$.next(null);
          }
        }

        this.navHistory.push(route);
        this.currentRoute$.next(route);
      })
    ).subscribe();

    this.userMenu$ = this.auth.user$.pipe(
      switchMap((user) => combineLatest([
          this.currentRoute$
        ]).pipe(
          map(([route]) => {
            if (user) {
              const userMenu = [
                // new MenuItem({
                //   label: 'Impostazioni',
                //   url: '/settings',
                //   icon: 'settings',
                //   imageType: 'icon',
                //   isActive: false,
                //   visible: true,
                // }, route),
                new MenuItem({
                  label: 'Profilo',
                  url: `/profile`,
                  imageType: 'icon',
                  icon: 'account_circle',
                  isActive: false,
                  visible: true
                }, route),
                new MenuItem({
                  label: 'Pagamenti',
                  url: `/payments`,
                  imageType: 'icon',
                  icon: 'paid',
                  isActive: false,
                  visible: this.auth.user$.value?.firstLogin === false
                }, route),
                new MenuItem({
                  label: 'Aiuto',
                  url: `/help`,
                  imageType: 'icon',
                  icon: 'help',
                  isActive: false,
                  visible: true
                }, route),
                new MenuItem({
                  label: 'Logout',
                  url: '',
                  icon: 'logout',
                  imageType: 'icon',
                  isActive: false,
                  visible: true,
                  color: "warn",
                  onClick: () => this.auth.logout$.next()
                }, route)
              ];
              if (userMenu.some(x => x.isActive)) {
                this.userMenuExpanded$.next(true);
              } else {
                this.userMenuExpanded$.next(false);
              }
              return userMenu;
            }

            return [];

          })
        )
      ));

    this.menuItems$ = this.auth.user$.pipe(
      switchMap((user) => combineLatest([
          this.currentRoute$,
          this.es.events$,
          this.es.selectedEvent$.pipe(
            debug('Selected Event')
          )
        ]).pipe(
          map(([route, events, selectedEvent]) => {
            let menu: AppMenu;
            let eventsMenu = new MenuItem({
              label: 'Eventi',
              url: '/events',
              icon: 'event',
              imageType: 'icon',
              isActive: false,
              visible: this.auth.user$.value?.firstLogin === false,
              badgeCounter: events.length,
              onClick: () => this.es.selectedEvent$.next(null)
            }, route);
            if (selectedEvent) {
              eventsMenu.children = [
                new MenuItem({
                  label: selectedEvent.title,
                  url: `/events/${selectedEvent.id}/overview`,
                  imageType: 'icon',
                  icon: 'home',
                  isActive: false,
                  visible: true
                }, route),
                new MenuItem({
                  label: 'Campi Personalizzati',
                  url: `/events/${selectedEvent.id}/form-fields`,
                  imageType: 'icon',
                  icon: 'contact_mail',
                  isActive: false,
                  visible: selectedEvent.enabled
                }, route),
                // new MenuItem({
                //   label: 'Liste',
                //   url: `/events/${selectedEvent.id}/lists`,
                //   imageType: 'icon',
                //   icon: 'format_list_bulleted',
                //   isActive: false,
                //   visible: true
                // }, route),
                new MenuItem({
                  label: 'Guest',
                  url: `/events/${selectedEvent.id}/guests`,
                  imageType: 'icon',
                  icon: 'people',
                  isActive: false,
                  visible: selectedEvent.enabled
                }, route),
                new MenuItem({
                  label: 'Gruppi',
                  url: `/events/${selectedEvent.id}/clusters`,
                  imageType: 'icon',
                  icon: 'groups',
                  isActive: false,
                  visible: selectedEvent.enabled
                }, route),
                new MenuItem({
                  label: 'Sito Web (Beta 🧪)',
                  url: `/events/${selectedEvent.id}/website`,
                  imageType: 'icon',
                  icon: 'web',
                  isActive: false,
                  visible: selectedEvent.enabled && selectedEvent.features.website
                }, route),
                new MenuItem({
                  label: 'Invia QR',
                  url: `/events/${selectedEvent.id}/qr-codes`,
                  imageType: 'icon',
                  icon: 'send',
                  isActive: false,
                  visible: selectedEvent.enabled && !selectedEvent.finished
                }, route),
                new MenuItem({
                  label: 'CheckIn',
                  mode: 'button',
                  url: `/events/${selectedEvent.id}/checkin`,
                  imageType: 'icon',
                  icon: 'qr_code_scanner',
                  class: 'mat-bg-success !text-white',
                  isActive: false,
                  visible: selectedEvent.enabled && !selectedEvent.finished
                }, route)
              ];
            }


            if (user) {
              menu = {
                common: [
                  // new MenuItem({
                  //   label: 'Dashboard',
                  //   url: '/dashboard',
                  //   icon: 'dashboard',
                  //   imageType: 'icon',
                  //   isActive: false,
                  //   visible: true
                  // }, route),
                  eventsMenu,
                  // new MenuItem({
                  //   label: 'Dev Test',
                  //   url: '/dev-test',
                  //   icon: 'bug_report',
                  //   imageType: 'icon',
                  //   isActive: false,
                  //   visible: true
                  // }, route),
                  // new MenuItem({
                  //   label: 'Utenti',
                  //   url: '/users',
                  //   icon: 'group',
                  //   imageType: 'icon',
                  //   isActive: false,
                  //   visible: true
                  // }, route)
                ],
                crm: [
                  new MenuItem({
                    label: 'Gestione liste',
                    url: '/user-lists',
                    icon: 'format_list_bulleted',
                    imageType: 'icon',
                    isActive: false,
                    visible: true
                  }, route),
                  new MenuItem({
                    label: 'Comunicazioni',
                    url: '/communications',
                    icon: 'contact_mail',
                    imageType: 'icon',
                    isActive: false,
                    visible: true
                  }, route)
                ],
                admin: [
                  new MenuItem({
                    label: 'Gestione UTENTI',
                    url: '/users',
                    icon: 'people',
                    imageType: 'icon',
                    isActive: false,
                    visible: this.auth.role() === 'admin'
                  }, route),
                  new MenuItem({
                    label: 'Promo code',
                    url: '/promo-code',
                    icon: 'local_activity',
                    imageType: 'icon',
                    isActive: false,
                    visible: this.auth.role() === 'admin'
                  }, route),
                  new MenuItem({
                    label: 'Form Builder',
                    url: '/form-builder',
                    icon: 'dynamic_form',
                    imageType: 'icon',
                    isActive: false,
                    visible: this.auth.role() === 'admin'
                  }, route),
                  new MenuItem({
                    label: 'Email Editor',
                    url: '/templates',
                    icon: 'email',
                    imageType: 'icon',
                    isActive: false,
                    visible: true
                  }, route),
                  // new MenuItem({
                  //   label: 'Test',
                  //   url: '/test',
                  //   icon: 'debug',
                  //   imageType: 'icon',
                  //   isActive: false,
                  //   visible: this.auth.role() === 'admin'
                  // }, route),
                  new MenuItem({
                    label: 'Log',
                    url: '/crm-log',
                    icon: 'storage',
                    imageType: 'icon',
                    isActive: false,
                    visible: this.auth.role() === 'admin'
                  }, route)
                ]
              };
            } else {
              menu = {
                common: [],
                crm: [],
                admin: []
              };
            }

            // (Object.keys(menu) as (keyof typeof menu)[]).forEach(
            //   (category) => menu[category]?.forEach(item => {
            //     item.isActive = item.url ? route.startsWith(item.url) : false
            //   }));

            return menu;
          }),
        )
      ),
      // debug('Menu',  {trace: true})
      debug('Menu')
    );

    this.accountCreditsData$ = combineLatest([
      this.auth.user$.pipe(notNullOrUndefined()),
      this.es.refreshCredits$
    ]).pipe(
      switchMap(([user, _]) =>
        this.http.get<AccountCreditsData>(`${environment.appUrl}/account/credits`).pipe(
          this.hs.httpErrorHandler({credits: 0, accountName: ''})
        )
      )
    );

  }

  getAccountCreditsInfo() {
    return this.http
  }
}
