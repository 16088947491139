<!--<mat-form-field [appearance]="props.appearance ?? 'outline'" class="w-full">-->

<!--  <mat-error *ngIf="formControl.hasError('dateNotBefore')">-->
<!--    TEST-->
<!--  </mat-error>-->
<!--</mat-form-field>-->

<input
  [errorStateMatcher]="errorStateMatcher"
  [formControl]="formControl"
  [formlyAttributes]="field"
  [id]="id"
  [max]="props.timepickerOptions?.max"
  [min]="props.timepickerOptions?.min"
  [placeholder]="props.placeholder ?? ''"
  [readonly]="props.readonly"
  [required]="required"
  matInput
  type="time"
/>
