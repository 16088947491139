import {Injectable} from '@angular/core';
import {BehaviorSubject, catchError, distinctUntilChanged, of, Subject, switchMap, tap, throwError} from 'rxjs';
import {User} from './classes/user';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {map} from 'rxjs/operators';
import {LoginData} from './classes/login-data';
import {ActivatedRoute, Router} from '@angular/router';
import {debug} from '../shared/rxjs/debug';
import {RegisterData} from './classes/register-data';
import {GlobalLoaderService} from '../shared/global-loader.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user$ = new BehaviorSubject<User | null>(null);
  logout$ = new Subject<void>();

  constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute, private gl: GlobalLoaderService) {
    this.user$.pipe(
      distinctUntilChanged(),
      debug('User'),
      tap((user) => {

        // if(user && .)
        // this.router.navigate(['/']);
      })
    ).subscribe();

    this.user$.next(JSON.parse(sessionStorage.getItem('currentUser') ?? 'null'));

    this.logout$.pipe(
      tap(() => this.gl.loading()),
      switchMap(() => this.http.post<User>(`${environment.apiUrl}/auth/logout`, {}, {withCredentials: true})
        .pipe(
          catchError((err) => of(null)),
          tap(() => {
            this.user$.next(null);
            sessionStorage.removeItem('currentUser');
            this.router.navigate(['/']);
          })
        )),
      tap(() => this.gl.loaded())
    ).subscribe();

  }

  emailLoginRequest(data: { email: string }) {
    return this.http.post<any>(`${environment.apiUrl}/auth/email-login-request`, data).pipe();
  }

  resendActivationEmail( email: string ) {
    return this.http.post<any>(`${environment.apiUrl}/auth/user/resend-activation-email`, {email}).pipe(
      catchError((err) => of(null))
    );
  }

  login(data: LoginData) {
    return this.http.post<User>(`${environment.apiUrl}/auth/login`, data, {withCredentials: true})
      .pipe(
        map(user => {
          if (user && user.token) {
            sessionStorage.setItem('currentUser', JSON.stringify(user));
            this.user$.next(user);
          }
          return user;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error.error);
        })
      );
  }

  loginWithUrl(data: { token: string }) {
    return this.http.post<User>(`${environment.apiUrl}/auth/email-login`, data, {withCredentials: true})
      .pipe(
        map(user => {
          if (user && user.token) {
            sessionStorage.setItem('currentUser', JSON.stringify(user));
            this.user$.next(user);
          }
          return user;
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.error instanceof ErrorEvent) {
            console.error('An error occurred:', error.error.message);
          }

          return throwError(() => error.error);
        })
      );
  }

  passwordReset(email: string) {
    return this.http.post<any>(`${environment.apiUrl}/auth/user/password-reset`, {email: email})
      .pipe(
      )
  }

  newPassword(h: string, t: string, newPassword: string) {
    return this.http.post<any>(`${environment.apiUrl}/auth/user/new-password?h=${h}&t=${t}`, {h, t, newPassword});
  }

  register(data: RegisterData) {
    return this.http.post<User>(`${environment.apiUrl}/auth/user/register/first-step`, data)
      .pipe(
      )
  }

  role() {
    return this.user$.value?.role ?? '';
  }
}
