import {Component} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {BehaviorSubject, catchError, Observable, of, Subject, switchMap, tap} from 'rxjs';
import {AuthService} from '../auth.service';
import {debug} from '../../shared/rxjs/debug';
import {Router} from '@angular/router';
import {HelperService} from "../../shared/helper.service";
import {tempTranslations} from '../../realguest/classes/temp-translations';
import {BaseRxjsComponent} from '../../shared/components/BaseRxjsComponent';
import {map} from 'rxjs/operators';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'nbd-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends BaseRxjsComponent {

  login$ = new Subject<void>();
  error$ = new BehaviorSubject<string>('');

  loginForm = this.fb.nonNullable.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', Validators.required],
    remember_me: [true, Validators.required]
  });

  hidePassword = true;

  showResendActivation$ = new BehaviorSubject<{email: string} | null>(null);

  constructor(private auth: AuthService,
              private fb: FormBuilder,
              private router: Router,
              private ts: TranslateService) {
    super();

    this.sub = this.login$.pipe(
      map(() => {
        this.loading();
        this.error$.next('');
        this.showResendActivation$.next(null);
        return this.loginForm.getRawValue();
      }),
      switchMap((loginData) => this.auth.login(loginData).pipe(
        debug('Login'),
        catchError((data) => {
          switch (data.error.description) {
            case 'userInactive':
              this.showResendActivation$.next(loginData);
              break;
            case 'invalidCredentials':
              this.error$.next(this.ts.instant('server.auth.invalidCredentials'));
              break;
            case 'tooManyAttempts':
              this.error$.next(this.ts.instant('server.auth.tooManyAttempts'));
              break;
            default:
              this.error$.next(this.ts.instant('server.auth.unknownError'));
          }
          return of(null);
        }),
        tap((data) => {
          this.loaded();
          if (data) {
            this.router.navigate(['/'])
          }
        })
      ))
    ).subscribe();
  }

  togglePasswordVisibility() {
    this.hidePassword = !this.hidePassword;
  }
}
