import {ThemePalette} from '@angular/material/core';

export class MenuItem {
  label: string = '';
  url?: string;
  icon?: string;
  isActive: boolean = false;
  imageType: "icon" | "image" | "none" = "none";
  visible: boolean = true;
  color?: ThemePalette;
  class?: string;
  mode?: 'list' | 'button';
  badgeCounter?: number;

  children?: MenuItem[];


  onClick?: () => void;

  constructor(item: Partial<MenuItem>, route?: string) {
    this.mode = 'list';
    Object.assign(this, item);
    if (route) {
      this.setRoute(route);
    }
  }

  setRoute(route: string) {
    this.isActive = this.url ? route.startsWith(this.url) : false;
  }
}
