@if (auth.user$ | async; as user) {
  <mat-sidenav-container autosize class="sidenav-container">
    <mat-sidenav #drawer [attr.role]="(hs.isHandset$ | async) ? 'dialog' : 'navigation'" [class.collapsed]="cs.collapseMenu$ | async"
      [class.mobile]="hs.isHandset$ | async"
      [mode]="(hs.isHandset$ | async) ? 'over' : 'side'"
      [opened]="(hs.isHandset$ | async) === false"
      class="sidenav"
      fixedInViewport>
      <mat-toolbar class="relative !justify-between space-x-4">
        <div class="py-3 w-full h-full !pr-8">
          <img alt="RealGuest Logo" class="object-contain h-full"
            src="{{environment.assetsBucketUrl}}/img/logo{{(cs.collapseMenu$ | async) ? '_small' : ''}}.png">
          </div>
        </mat-toolbar>
        @if (auth.user$ | async; as user) {
          <mat-expansion-panel [expanded]="cs.userMenuExpanded$ | async" class="mat-elevation-z0 menu-item">
            <mat-expansion-panel-header class="!h-auto !pl-0">
              <div class="p-4 grid grid-cols-[max-content_1fr] items-center gap-2">
                <img [class.collapsed]="cs.collapseMenu$ | async" alt="User Image" class="avatar-image"
                  src="./assets/img/user.png">
                  @if (!(cs.collapseMenu$ | async)) {
                    <div class="flex flex-col">
                      <strong>{{ user.firstName }} {{ user.lastName }}</strong>
                      <div class="mat-caption">{{ user.role }}</div>
                    </div>
                  }
                </div>
              </mat-expansion-panel-header>
              @if (cs.userMenu$ | async; as menuItems) {
                <mat-nav-list dense>
                  @for (m of menuItems; track m.label) {
                    <nbd-nav-menu-item [collapsed]="(cs.collapseMenu$ | async) ?? false"
                      [drawer]="drawer"
                      [isHandset]="(hs.isHandset$ | async) ?? false"
                    [m]="m"></nbd-nav-menu-item>
                  }
                </mat-nav-list>
              }
            </mat-expansion-panel>
            <mat-divider></mat-divider>
            @if (user.firstLogin ) {
              <mat-nav-list >
                <div class="px-4">
                  <h4 class="!mb-2"><strong>Profilo</strong></h4>
                  <p class="mat-caption">Completa il tuo profilo per iniziare a usare RealGuest!</p>
                  <button class="w-full my-2"
                    color="accent"
                    mat-raised-button routerLink="/profile">
                    {{ 'COMPLETA IL PROFILO' }}
                  </button>
                </div>
              </mat-nav-list>
              <mat-divider></mat-divider>
            }
            <!--      <nbd-loading-box [item]="cs.accountCreditsData$ | async" #creditsBox>-->
            <!--        <mat-nav-list>-->
            <!--          <div class="px-4">-->
            <!--            <h4 class="!mb-2"><strong>Crediti</strong></h4>-->
            <!--            <p class="mat-caption">Ogni <em>Guest</em> vale 1 credito.<br>-->
            <!--              Sull'account <strong>{{ creditsBox.item?.accountName }}</strong> hai ancora</p>-->
            <!--            <div class="w-full flex flex-col justify-center items-center my-1">-->
            <!--              <strong class="text-3xl">{{ creditsBox.item?.credits }}</strong>-->
            <!--              <span class="mat-caption">crediti</span>-->
          <!--            </div>-->
          <!--            <button mat-raised-button-->
          <!--                    routerLink="{{user.firstLogin ? '/profile' : '/payments'}}"-->
          <!--                    color="accent" class="w-full my-2">-->
          <!--              {{user.firstLogin ? 'COMPLETA IL PROFILO' : 'AGGIUNGI CREDITI'}}-->
        <!--            </button>-->
      <!--          </div>-->
    <!--        </mat-nav-list>-->
  <!--      </nbd-loading-box>-->
}
@if (cs.menuItems$ | async; as menuItems) {
  <mat-nav-list dense>
    @if (menuItems.common?.length ?? 0 > 0) {
      @for (m of menuItems.common; track m) {
        <nbd-nav-menu-item
          [collapsed]="(cs.collapseMenu$ | async) ?? false"
          [drawer]="drawer"
          [isHandset]="(hs.isHandset$ | async) ?? false"
        [m]="m"></nbd-nav-menu-item>
      }
    }
    <!--      <ng-container *ngIf="menuItems.crm?.length ?? 0 > 0">-->
    <!--        <nbd-nav-menu-item [m]="m" *ngFor="let m of menuItems.crm"></nbd-nav-menu-item>-->
  <!--      </ng-container>-->
  @if (user.role === 'admin' && menuItems.admin?.length ?? 0 > 0) {
    <mat-divider></mat-divider>
    @for (m of menuItems.admin; track m) {
      <nbd-nav-menu-item [collapsed]="(cs.collapseMenu$ | async) ?? false"
        [drawer]="drawer"
        [isHandset]="(hs.isHandset$ | async) ?? false"
      [m]="m"></nbd-nav-menu-item>
    }
  }
</mat-nav-list>
}
</mat-sidenav>
<mat-sidenav-content class="!grid grid-cols-1 grid-rows-[max-content_1fr] !overflow-visible">
  <mat-toolbar color="primary">
    <!--      <ng-container *ngIf="!(hs.isHandset$ | async)">-->
    <!--        <button mat-mini-fab (click)="toggleMenu()" class="desktop-menu-button">-->
    <!--          <mat-icon>{{collapseMenu ? 'chevron_right' : 'chevron_left'}}</mat-icon>-->
  <!--        </button>-->
<!--      </ng-container>-->
@if (hs.isHandset$ | async;) {
  <button
    (click)="drawer.toggle()"
    aria-label="Toggle sidenav"
    mat-icon-button
    type="button">
    <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
  </button>
}
<nbd-toolbar class="ml-6"></nbd-toolbar>
<!--      <div class="flex" *ngIf="(hs.showBackBtn$ | async) && cs.navHistory.size() > 0">-->
<!--        <button mat-fab extended color="primary" class="mat-no-shadow" (click)="back()">-->
<!--          <mat-icon>arrow_back_ios</mat-icon>-->
<!--          Indietro-->
<!--        </button>-->
<!--      </div>-->
<ng-template toolbarMenuHost>
</ng-template>
</mat-toolbar>
<div class="w-full h-full overflow-y-auto relative overflow-hidden">
  <!--      <ng-content></ng-content>-->
  <router-outlet></router-outlet>
</div>
</mat-sidenav-content>
</mat-sidenav-container>
} @else {
  <router-outlet></router-outlet>
  <!--  <ng-content></ng-content>-->
}


@if (gl.globalLoader$ | async) {
  <div
    class="absolute w-full h-full top-0 left-0 bg-gray-50/60"
    style="z-index: 1000">
    <nbd-loader [fill]="true"></nbd-loader>
  </div>
}
