import {Component} from '@angular/core';
import {BehaviorSubject, catchError, exhaustMap, Observable, of, Subject, switchMap, tap} from 'rxjs';
import {FormBuilder, Validators} from '@angular/forms';
import {AuthService} from '../auth.service';
import {Router} from '@angular/router';
import {debug} from '../../shared/rxjs/debug';
import {DateTime} from 'luxon';
import {HelperService} from "../../shared/helper.service";
import {PasswordValidator} from '../classes/PasswordValidators';
import {MustMatchValidator} from '../../formly-components/validators/MustMatchValidator';
import {tempTranslations} from '../../realguest/classes/temp-translations';
import {environment} from '../../../environments/environment';
import {ReCaptchaV3Service} from 'ng-recaptcha';
import {map} from 'rxjs/operators';
// import {PasswordValidator} from "../classes/PasswordValidators";
// import {MustMatch} from "../classes/MustMatch";

@Component({
  selector: 'nbd-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent {

  loading$ = new BehaviorSubject<boolean>(false);
  register$ = new Subject<void>();
  error$ = new BehaviorSubject<string>('');

  sub$: Observable<any>;

  hidePassword = true;
  hidePassword2 = true;

  registerForm = this.fb.nonNullable.group({
    firstName: ['', [Validators.required]],
    lastName: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email]],
    confirmPassword: ['', Validators.required],
    password: ['', [
      Validators.required,
      Validators.minLength(8),
      PasswordValidator.hasNumber(),
      // PasswordValidator.hasSpecialChars(),
      PasswordValidator.hasUppercase(),
      PasswordValidator.hasLowercase(),
    ]],
    // dob: ['', [Validators.required]],
    // phone: [''],
    // address: [''],
    acceptTerms: [false, Validators.requiredTrue]
  }, {
    validators: MustMatchValidator.mustMatch('password', 'confirmPassword')
  });

  maxDate = DateTime.now().minus({years: 18});
  completed = false;

  constructor(private auth: AuthService,
              private fb: FormBuilder,
              private rcv3s: ReCaptchaV3Service,
              private router: Router,
              private hs: HelperService) {


    this.sub$ = this.register$.pipe(
      map(() => {
        this.loading$.next(true);
        this.error$.next('');
        return this.registerForm.getRawValue();
      }),
      exhaustMap((data) => this.rcv3s.execute('register').pipe(
        catchError((err) => {
          // console.error(err);
          return of(null);
        }),
        map((token) => ({
          ...data,
          reCaptcha: token
        })),
        switchMap((formData) => this.auth.register(formData).pipe(
          debug('Register'),
          catchError((error) => {
            if (error.error.description === 'emailExists') {
              this.hs.error('Errore', tempTranslations.auth['emailExists']);
            }
            if (error.error.description === 'invalidCaptcha') {
              this.hs.error('Errore', tempTranslations.auth['invalidCaptcha']);
            }
            // else {
            //   this.hs.error('Errore', 'Email di registrazione');
            //   // this.error$.next('Errore di registrazione');
            // }
            // if (error.description === 'userInactive') {
            //   this.errorMessage = error.message;
            //   this.activationLink = true;
            //   this.sendActivationTo = this.usernameFormControl.value;
            // } else {
            //   this.errorMessage = error.message ? error.message : trans('auth.login.loginError');
            // }
            // this.error = true;
            return of(null);
          }),
          tap((data) => {
            this.loading$.next(false);
            if (data) {
              this.completed = true;
            }
            // this.router.navigate(['/'])

            // if (data !== null) {
            //   const u = data;
            //
            //   if (u.role === 'admin') {
            //     this.router.navigate(['/']);
            //     return;
            //   }
            // }

          })
        )),
      ))
    );
  }

  togglePasswordVisibility() {
    this.hidePassword = !this.hidePassword;
  }

  protected readonly environment = environment;
}
