import {Component, Input} from '@angular/core';
import {Clipboard} from '@angular/cdk/clipboard';

@Component({
  selector: 'nbd-share-buttons',
  templateUrl: './share-buttons.component.html',
  styleUrls: ['./share-buttons.component.scss']
})
export class ShareButtonsComponent {

  @Input() url?: string;
  @Input() title?: string;
  @Input() description?: string;

  webShareSupported = false;

  constructor(public clipboard: Clipboard) {
    // Check if Web Share API is supported
    this.webShareSupported = 'share' in navigator;
  }

  async share() {
    if (this.webShareSupported) {
      try {
        const share = {
          title: this.title,
          text: this.description,
          url: this.url
        };
        console.log(share)
        await navigator.share(share);
      } catch (error) {
        console.error('Error sharing URL:', error);
      }
    }
  }

}
