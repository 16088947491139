import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export class PasswordValidator {
  static pattern(regex: RegExp, error: ValidationErrors): ValidatorFn {

    return (control: AbstractControl): { [p: string]: any } | null => {
      if (!control.value) {
        // if control is empty return no error
        return null;
      }

      // test the value of the control against the regexp supplied
      const valid = regex.test(control.value);

      // if true, return no error (no error), else return error passed in the second parameter
      return valid ? null : error;
    };
  }

  static hasNumber() {
    return PasswordValidator.pattern(/\d/, {hasNumber: true});
  }

  static hasUppercase() {
    return PasswordValidator.pattern(/[A-Z]/, {hasUppercase: true});
  }

  static hasLowercase() {
    return PasswordValidator.pattern(/[a-z]/, {hasLowercase: true});
  }

  static hasSpecialChars() {
    return PasswordValidator.pattern(/[\W_]/, {hasSpecialChars: true});
  }

  static hasNumberOrSpecial() {
    return PasswordValidator.pattern(/[\d\W_]/, {hasNumberOrSpecial: true});
  }
}
