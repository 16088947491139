<div class="h-full flex items-center">
  <div class="container text-center mx-auto">
    <div class="row">
      <div class="col">
        <span class="mat-headline-6">{{ 'auth.forgot-password.title' | translate }}</span><br>
      </div>
    </div>
    <div class="row">
      <div class="col">
        @if (!sent) {
          <span
            class="mat-body-1">{{ 'auth.forgot-password.first-message' | translate }}</span>
        }
        @if (sent) {
          <span
            class="mat-body-1">{{ 'auth.forgot-password.second-message' | translate }}</span>
        }
      </div>
    </div>
    @if (!sent) {
      <div class="row mt-4">
        <div class="col">
          <mat-form-field appearance="outline"
                          class="w-full max-w-[400px] mail-field text-normal"
                          color="primary">
            <mat-label>{{ 'common.email' | translate }}</mat-label>
            <input [formControl]="email" matInput [placeholder]="'common.email-placeholder' | translate" required>
            <div class="mat-caption">
              @if (email.errors?.['email']) {
                <mat-error>{{ 'common.errors.email.invalid' | translate }}</mat-error>
              }
            </div>
          </mat-form-field>
        </div>
      </div>
    }
    @if (!sent) {
      <div class="row">
        <div class="col">
          <button (click)="forgotPassword$.next()" [disabled]="!email.valid" class="mt-4 btn-pl23-radius" color="accent"
                  mat-flat-button>{{ 'common.confirm' | translate }}
          </button>
        </div>
      </div>
    }
  </div>
</div>
