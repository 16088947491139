<div class="h-full w-full grid grid-cols-1 grid-rows-[1fr_max-content] relative">

  @if (dataSource) {
    <div class="overflow-auto relative">
      <!-- TABLE -->
      <table (matSortChange)="setSortData($event)"
        [dataSource]="dataSource" mat-table
        matSort>
        <!-- HEADERS -->
        @if (navigationColumn && navigationId && navigationUrl) {
          <ng-container matColumnDef="crm_nav" sticky>
            <th *matHeaderCellDef class="!w-[80px]" mat-header-cell>
              @if (allowSelection) {
                <button (click)="toggleSelection$.next(!toggleSelection$.value)" mat-icon-button>
                  @if (selectionEnabled$ | async) {
                    <mat-icon>close</mat-icon>
                  } @else {
                    <mat-icon>check_box_outline_blank</mat-icon>
                  }
                </button>
              }
            </th>
            <td *matCellDef="let element;" mat-cell>
              <div class="flex space-x-1">
                <!--            <button mat-icon-button aria-label="Azioni">-->
                <!--              <mat-icon>more_vert</mat-icon>-->
              <!--            </button>-->
              @if (selectionEnabled$ | async) {
                <mat-checkbox (change)="addOrRemoveItem($event.checked, element[navigationId])"
                  [checked]="selectedItemsMap.has(element[navigationId])"
                  [indeterminate]="false"
                  color="accent">
                </mat-checkbox>
              } @else {
                <button aria-label="Dettagli" mat-icon-button
                  routerLink="{{navigationUrl.replace(':'+navigationId, element[navigationId])}}">
                  <mat-icon>arrow_circle_right</mat-icon>
                </button>
              }
            </div>
          </td>
        </ng-container>
      }
      @for (columnConfig of config; track columnConfig) {
        <ng-container [matColumnDef]="columnConfig.columnName">
          @if (!columnConfig.notInTable) {
            <th *matHeaderCellDef
              [disabled]="!columnConfig.sortable || !!currentResizingColumn"
              class="header-with-padding"
              mat-header-cell
              mat-sort-header>
              <div class="whitespace-nowrap">
                {{ columnConfig.displayName }}
                @if (getTextFilter(columnConfig.columnName).length > 0) {
                  <span
                  class="mat-caption">: {{ getTextFilter(columnConfig.columnName) }}</span>
                }
                <!--            <div *ngIf="columnConfig.textFilter" class="icon-filler"></div>-->
                <!--            <div *ngIf="columnConfig.generateFilters" class="icon-filler"></div>-->
                <!--            <div class="icon-filler"></div>-->
              </div>
              <div class="absolute right-0 ">
                <!--          <div class="ml-auto flex items-center">-->
                <!-- TEXT FILTER BUTTON -->
                @if (columnConfig.textFilter) {
                  <button (click)="$event.stopPropagation()"
                    (menuOpened)="searchInput.focus()"
                    [color]="getTextFilter(columnConfig.columnName).length > 0 ? 'warn' : undefined"
                    [disabled]="!!currentResizingColumn"
                    [matMenuTriggerFor]="searchMenu"
                    mat-icon-button>
                    <mat-icon>search</mat-icon>
                  </button>
                }
                <!-- TEXT FILTER INPUT -->
                <mat-menu #searchMenu="matMenu">
                  <mat-form-field (click)="$event.stopPropagation()" appearance="outline" class="m-2">
                    <mat-label>Cerca</mat-label>
                    <input #input #searchInput (keyup)="dataSource.textFilter$.next({columnName: columnConfig.columnName, textFilter: searchInput.value})"
                      [value]="getTextFilter(columnConfig.columnName)"
                      autofocus
                      matInput placeholder="Es. Mario">
                      <button (click)="searchInput.value = ''; dataSource.textFilter$.next({columnName: columnConfig.columnName, textFilter: searchInput.value})" [attr.aria-label]="'Cancella'"
                        [disabled]="searchInput.value.length === 0"
                        mat-icon-button
                        matSuffix>
                        <mat-icon>{{ searchInput.value.length > 0 ? 'clear' : 'search' }}</mat-icon>
                      </button>
                    </mat-form-field>
                  </mat-menu>
                  <!-- GROUP FILTERS -->
                  @if (columnConfig.generateFilters) {
                    <!-- GROUP FILTER BUTTON -->
                    <button (click)="$event.stopPropagation(); selectGroupFilter$.next(columnConfig.columnName)" [color]="isGroupFilterActive(columnConfig.columnName) ? 'warn' : undefined"
                      [matMenuTriggerFor]="filterMenu"
                      mat-icon-button>
                      <mat-icon>filter_list</mat-icon>
                    </button>
                    <!-- GROUP FILTER MENU -->
                    <mat-menu #filterMenu="matMenu" (closed)="selectGroupFilter$.next(null)" class="!w-[300px]">
                      <ng-template matMenuContent>
                        @if (groupsFilterList$ | async; as groups) {
                          <mat-selection-list #groupList (selectionChange)="sendItemsSelection(columnConfig.columnName, groupList.selectedOptions)"
                            multiple>
                            @if (columnConfig.type === columnType.Date || columnConfig.type === columnType.DateTime) {
                              @for (f of groups; track f) {
                                <mat-list-option (click)="$event.stopPropagation();"
                                  [selected]="isGroupOptionSelected(columnConfig.columnName, f)"
                                  [value]="f">
                                  {{ f === 'NULL' ? 'Vuoto' : 'Non Vuoto' }}
                                </mat-list-option>
                              }
                            } @else {
                              @for (f of groups; track f) {
                                <mat-list-option (click)="$event.stopPropagation();"
                                  [selected]="isGroupOptionSelected(columnConfig.columnName, f)"
                                  [value]="f">
                                  <nbd-crm-item-renderer [columnConfig]="columnConfig" [item]="f"></nbd-crm-item-renderer>
                                </mat-list-option>
                              }
                            }
                          </mat-selection-list>
                        } @else {
                          <div class="w-full p-4">
                            <mat-progress-bar class="w-full" mode="indeterminate"></mat-progress-bar>
                          </div>
                        }
                      </ng-template>
                    </mat-menu>
                  }
                  <mat-icon (click)="$event.stopPropagation()"
                    (mousedown)="startResize(columnConfig.columnName, $event)"
                    class="resize-handle">drag_handle
                  </mat-icon>
                </div>
              </th>
              <!-- CELL DATA -->
              <td *matCellDef="let element" mat-cell>
                <nbd-crm-item-renderer [class.opacity-60]="element.deleted" [columnConfig]="columnConfig"
                  [item]="element[columnConfig.columnName]"
                  [rowData]="element">
                </nbd-crm-item-renderer>
              </td>
            }
          </ng-container>
        }
        @if (dataSource.visibleColumns$ | async; as visibleColumns) {
          <tr *matHeaderRowDef="visibleColumns; sticky: true" mat-header-row></tr>
          <tr *matRowDef="let row; columns: visibleColumns;" mat-row></tr>
        }
        <!-- EMPTY DATA ROW -->
        <tr *matNoDataRow class="mat-row">
          <td class="mat-cell p-4 mat-text-warn" colspan="4">Nessun dato</td>
        </tr>
      </table>
    </div>
  }

  <div class="flex justify-between mat-bg-card items-center">

    <!-- ACTION BUTTONS-->
    <div class="grid grid-cols-3 place-items-center gap-2">

      <button (click)="dataSource.refresh$.next(true)" color="primary" mat-icon-button matTooltip="Aggiorna dati">
        <mat-icon>refresh</mat-icon>
      </button>

      @if (allowColumnSelection) {
        <button [matMenuTriggerFor]="columnsMenu" color="primary" mat-icon-button
          matTooltip="Seleziona colonne">
          <mat-icon>rule</mat-icon>
        </button>
      }

      @if (showExportButton) {
        <button (click)="export$.next()" class="mat-no-shadow"
          color="primary"
          mat-mini-fab
          matTooltip="Esporta Excel">
          <mat-icon>download</mat-icon>
        </button>
      }

      <!-- SHOW COLUMNS MENU -->
      <mat-menu #columnsMenu="matMenu" class="!max-w-none">
        @if (auth.role() !== 'crm-sgr') {
          <div class="p-4">
            <mat-slide-toggle (change)="dataSource.showDeleted$.next($event.checked)"
              [checked]="dataSource.showDeleted$ | async"
              color="warn">Mostra eliminati
            </mat-slide-toggle>
          </div>
          <mat-divider></mat-divider>
        }

        <mat-selection-list (selectionChange)="setSelectedColumns()"
          class="!grid grid-rows-8 grid-flow-col auto-cols-max">
          @for (c of config; track c) {
            @if (!c.notInTable && !c.disabledFor?.includes(auth.role())) {
              <mat-list-option (click)="$event.stopPropagation();"
                [(selected)]="c.visible"
                [disabled]="!!c.isIndex">
                {{ c.displayName }}
              </mat-list-option>
            }
          }

        </mat-selection-list>
        <mat-divider></mat-divider>
        <button (click)="$event.stopPropagation();selectAll(true)"
          mat-menu-item>
          <mat-icon>done_all</mat-icon>
          <span>Seleziona tutto</span>
        </button>
        <button (click)="$event.stopPropagation();selectAll(false)"
          mat-menu-item>
          <mat-icon>remove_done</mat-icon>
          <span>Deseleziona tutto</span>
        </button>
      </mat-menu>
    </div>

    <mat-paginator (page)="setPageData($event)"
      [length]="dataSource.data$.value.count"
      [pageSizeOptions]="[25, 50, 100, 250]"
      [pageSize]="(dataSource.pageSize$ | async) ?? 25"
      aria-label="Seleziona la pagina">
    </mat-paginator>
  </div>


  @if (dataSource.loading$ | async) {
    <div
      class="w-full h-full grid place-items-center absolute top-0 left-0 bg-gray-100/80 z-[1000]">
      <nbd-loader></nbd-loader>
    </div>
  }

</div>
