import {HttpErrorResponse} from '@angular/common/http';
import {tempTranslations} from '../../realguest/classes/temp-translations';

export function httpErrorMessageHandler(res: HttpErrorResponse) {
  if (res.status === 0) {
    // A client-side or network error occurred. Handle it accordingly.
    return `Si è verificato un errore: ${res.error.message ?? 'impossibile contattare il server'}`;
  } else {
    // The backend returned an unsuccessful response code.
    return tempTranslations.errors[res.error.error.description] ?? 'Errore sconosciuto';
  }
}
