import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalLoaderService {


  globalLoader$ = new BehaviorSubject<boolean>(false);


  constructor() {
  }

  public loading() {
    this.globalLoader$.next(true);
  }

  public loaded() {
    this.globalLoader$.next(false);
  }
}
