import {Component, Input} from '@angular/core';
import {RgEvent} from '../../realguest/classes/rg-event';
import {exhaustMap, Subject, switchMap, take, tap} from "rxjs";
import {isTrue} from "../../shared/rxjs/isTrue";
import {BaseRxjsComponent} from "../../shared/components/BaseRxjsComponent";
import {HelperService} from "../../shared/helper.service";
import {EventsService} from "../../realguest/services/events.service";
import {debug} from '../../shared/rxjs/debug';
import {map} from 'rxjs/operators';
import {RouterLink} from '@angular/router';
import {MatDivider} from '@angular/material/divider';
import {EventDayMonthComponentComponent} from './event-day-month-component/event-day-month-component.component';
import {EventStatusBadgeComponent} from '../event-status-badge/event-status-badge.component';

@Component({
  standalone: true,
  selector: 'nbd-selected-event',
  templateUrl: './selected-event.component.html',
  imports: [
    RouterLink,
    MatDivider,
    EventDayMonthComponentComponent,
    EventStatusBadgeComponent
  ],
  styleUrls: ['./selected-event.component.scss']
})
export class SelectedEventComponent extends BaseRxjsComponent {

  @Input() event?: RgEvent | null;

  activateEvent$ = new Subject<void>();

  constructor(private hs: HelperService,
              private es: EventsService) {
    super();

    this.sub = this.activateEvent$.pipe(
      exhaustMap(() =>
        this.hs.confirm("Attivazione Evento",
          `L'attivazione di questo evento consumerà <strong>${this.event!.guests} crediti</strong>.<br>
                   Ti ricordiamo che i crediti acquistati non sono rimborsabili e saranno scalati al momento dell’attivazione dell’evento in misura uguale al numero di ospiti gestiti.<br><br>
                    Confermi di voler procedere con l'attivazione?`).pipe(
          isTrue(),
          tap(() => this.loading()),
          switchMap(() => this.es.activateEvent(this.event!.id).pipe(
            map((res) => res ? this.event!.id : null)
          )),
          debug('Activate Event'),
          tap((eventId) => {
            if (eventId !== null) {
              this.es.refreshCredits();
              this.es.getEvent(eventId).pipe(take(1)).subscribe();
            }
            this.loaded();
          })
        )
      ),
    ).subscribe();
  }
}
