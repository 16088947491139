@if (formTemplate$ | async; as formTemplate) {
  <form (ngSubmit)="submit$.next(form.getRawValue())"
    [formGroup]="form" class="pb-4">
    <formly-form [fields]="formTemplate" [form]="form" [model]="item" [options]="options">
    </formly-form>
    @if (!readonly) {
      <div class="w-full flex justify-end space-x-4">
        @if (showCancel) {
          <button (click)="cancel$.next()"
            class="!min-w-[200px]"
            mat-flat-button
            type="button">
            ANNULLA
          </button>
        }
        @if (showSubmit) {
          <button [disabled]="!form.valid"
            class="!min-w-[200px]"
            color="primary"
            mat-raised-button
            type="submit">{{ submitActionText }}
          </button>
        }
      </div>
    }
  </form>
} @else {
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
}
