import {BehaviorSubject, Subject, Subscription} from 'rxjs';
import {Component, OnDestroy} from '@angular/core';

@Component({
  selector: '',
  standalone: true,
  template: ''
})
export class BaseRxjsComponent implements OnDestroy {

  refresh$ = new BehaviorSubject<boolean>(true);
  loading$ = new BehaviorSubject<boolean>(false);
  destroyed$ = new Subject<void>();

  private _sub = new Subscription();

  set sub(value: Subscription) {
    this._sub.add(value);
  }

  loading() {
    this.loading$.next(true);
  }

  loaded() {
    this.loading$.next(false);
  }

  refresh() {
    this.refresh$.next(true);
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this._sub.unsubscribe();
  }

}
