import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {OrderDetail} from './classes/OrderDetail';
import {environment} from '../../environments/environment';
import {HelperService} from '../shared/helper.service';
import {EventPlan} from './classes/EventPlan';
import {Observable} from 'rxjs';
import {Transaction} from './classes/Transaction';
import {Payment} from './classes/Payment';
import {RgEvent} from '../realguest/classes/rg-event';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  availablePlans$: Observable<EventPlan[]>;

  constructor(private http: HttpClient, private hs: HelperService) {
    this.availablePlans$ = this.getAvailablePlans();
  }

  getAvailablePlans(): Observable<EventPlan[]> {
    return this.http.get<EventPlan[]>(`${environment.appUrl}/account/available-plans`).pipe(
      this.hs.httpErrorHandler([])
    )
  }

  getOrders() {
    return this.http.get<Payment[]>(`${environment.appUrl}/account/orders`).pipe(
      this.hs.httpErrorHandler([])
    )
  }

  getTransactions() {
    return this.http.get<Transaction[]>(`${environment.appUrl}/account/transactions`).pipe(
      this.hs.httpErrorHandler([])
    )
  }

  getOrderDetail(orderId: string) {
    return this.http.get<OrderDetail>(`${environment.appUrl}/account/orders/${orderId}`).pipe(
      this.hs.httpErrorHandler(null)
    )
  }

  redeemPromoCode(promoCode: string, eventId: string) {
    return this.http.post<{ balance: number }>(`${environment.appUrl}/events/${eventId}/promo-code`, {promoCode}).pipe(
      this.hs.httpErrorHandler(null)
    )
  }

  getCalculatedCost(plan: EventPlan, features?: RgEvent['features']) {
    let cost = plan.cost;
    if(features) {
      if(features.sms) {
        cost += plan.features.sms;
      }

      if(features.email) {
        cost += plan.features.email;
      }

      if(features.website) {
        cost += plan.features.website;
      }
    }

    return cost;
  }
}
