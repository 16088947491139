import {Component, Input} from '@angular/core';

@Component({
  selector: 'nbd-counter-item',
  templateUrl: './counter-item.component.html',
  styleUrls: ['./counter-item.component.scss']
})
export class CounterItemComponent {
  @Input() value: string | number | null = 0;
  @Input() label: string = '';
}
