import {Component} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {KeyValue} from '@angular/common';
import {CrmDataService} from '../../crm-data.service';
import {map} from 'rxjs/operators';
import {notNullOrUndefined} from '../../rxjs/notNullOrUndefined';


@Component({
  selector: 'nbd-user-keywords',
  templateUrl: './user-keywords.component.html',
  styleUrls: ['./user-keywords.component.scss']
})
export class UserKeywordsComponent {

  keywords$: Observable<KeyValue<string, string>[]>;

  constructor(private http: HttpClient, private crm: CrmDataService) {
    this.keywords$ = this.crm.getTableConfig('users').pipe(
      notNullOrUndefined(),
      map((config) => config.map(c => ({
        key: c.displayName,
        value: c.columnName
      })))
    );
  }
}
