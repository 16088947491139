import {Component, Input, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {FormlyFieldConfig, FormlyFormOptions} from '@ngx-formly/core';
import {BehaviorSubject, combineLatest, delay, Observable, of, Subject, switchMap, tap} from 'rxjs';
import {CrmDataService} from '../../crm-data.service';
import {map} from 'rxjs/operators';
import {debug} from '../../rxjs/debug';
import {BaseRxjsComponent} from '../BaseRxjsComponent';

@Component({
  selector: 'nbd-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent<T> extends BaseRxjsComponent {

  form: FormGroup;
  formTemplate$?: Observable<FormlyFieldConfig[]>;

  additionalFields$ = new BehaviorSubject<FormlyFieldConfig[]>([]);
  formKey$ = new BehaviorSubject<string | null>(null);
  fieldChanges$ = new Subject<any>();

  @Input() item?: T | null;
  // @Input() options: FormlyFormOptions = {};
  options: FormlyFormOptions = {
    fieldChanges: this.fieldChanges$,
    formState: {
      disabled: false
    }
  };
  @Input() submitActionText = "SALVA";
  @Input() showCancel = false;
  @Input() showSubmit = false;
  @Input() readonly = false;
  @Output() submit$ = new Subject<any>();
  @Output() cancel$ = new Subject<void>();

  constructor(private crm: CrmDataService) {
    super();

    this.loading();
    // this.sub = this.fieldChanges$.pipe(
    //   tap(() => {
    //     this.readonly ? this.form.disable() : this.form.enable()
    //   })
    // ).subscribe();

    this.form = new FormGroup({});

    // this.options = {
    //   ...this.options,
    //   fieldChanges: this.fieldChanges$
    // }

    this.formTemplate$ = combineLatest([
      this.formKey$.pipe(
        switchMap((key) => {
          if (key) {
            return this.crm.getFormTemplate(key)
          }
          return of([]);
        })
      ),
      this.additionalFields$
    ]).pipe(
      debug('Templates'),
      map(([config, additionalConfig]) => [...config, ...additionalConfig]),
      delay(30),
      tap(() => this.loaded())
    );


  }

  @Input() set enabled(value: boolean) {
    this.options.formState.disabled = !value;
    // this.readonly = !value;
    //
    // if(value) {
    //   this.form.enable();
    // } else {
    //   this.form.disable();
    // }
  }

  @Input() set formKey(key: string | null) {
    this.formKey$.next(key);
  }

  @Input() set additionalFields(config: FormlyFieldConfig[]) {
    this.additionalFields$.next(config);
  }

}
