import {Component} from '@angular/core';

@Component({
  selector: 'nbd-auth-flow',
  templateUrl: './auth-flow.component.html',
  styleUrls: ['./auth-flow.component.scss']
})
export class AuthFlowComponent {

}
