import {Component} from '@angular/core';
import {FieldArrayType} from '@ngx-formly/core';

@Component({
  selector: 'nbd-array-type',
  templateUrl: './array-type.component.html',
  styleUrls: ['./array-type.component.scss']
})
export class ArrayTypeComponent extends FieldArrayType {
}
