<h1 mat-dialog-title>{{ data.title }}</h1>
<div mat-dialog-content>
  <p [innerHTML]="data.message | safe:'html'"></p>
  <mat-form-field appearance="outline" class="w-full">
    <mat-label>{{ data.fieldLabel }}</mat-label>
    <mat-select #select>
      @for (o of data.options; track o) {
        <mat-option [value]="o.value">
          {{ o.label }}
        </mat-option>
      }
    </mat-select>
  </mat-form-field>
</div>

<div align="end" mat-dialog-actions>
  <button [disabled]="!select.value"
    [mat-dialog-close]="select.value"
    color="primary"
    mat-flat-button>
    {{ data.confirm ?? 'OK' }}
  </button>
  <button [mat-dialog-close]="null" mat-button>{{ data.deny ?? 'CANCEL' }}</button>
</div>
