import {AbstractControl, ValidationErrors} from '@angular/forms';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {DateTime} from 'luxon';
import {DotToValuePipe} from '../../shared/pipes/dot-to-value.pipe';

export abstract class DateNotBeforeFieldValidator {

  static validator(control: AbstractControl, field: FormlyFieldConfig, options: any): ValidationErrors | null {

    const dot = new DotToValuePipe();

    const sourceValue = dot.transform(control.value, options.source) as string;
    const targetValue = dot.transform(control.value, options.target) as string;

    if (!sourceValue || !targetValue) {
      return null;
    }

    if (DateTime.fromISO(sourceValue) >= DateTime.fromISO(targetValue)) {
      return {dateNotBefore: {message: `Deve essere dopo il ${DateTime.fromISO(sourceValue).toFormat('dd/MM/y HH:mm')}`}};
    }

    return null;

  }


}
