import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './login/login.component';
import {AuthFlowComponent} from './auth-flow/auth-flow.component';
import {RegisterComponent} from './register/register.component';
import {EmailConfirmedComponent} from './email-confirmed/email-confirmed.component';
import {NewPasswordComponent} from "./new-password/new-password.component";
import {ForgotPasswordComponent} from "./forgot-password/forgot-password.component";

const routes: Routes = [
  {
    path: '', component: AuthFlowComponent, children: [
      {path: 'login', component: LoginComponent},
      {path: 'email-confirmed', component: EmailConfirmedComponent},
      // {path: 'login', component: RequestUrlLoginComponent},
      // {path: 'url-login', component: UrlLoginComponent},
      {path: 'register', component: RegisterComponent},
      {path: '', pathMatch: 'full', redirectTo: 'login'},
      {path: 'forgot-password', component: ForgotPasswordComponent},
      {path: 'new-password', component: NewPasswordComponent},
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule {
}
