@if(event) {
  <div class="grid gap-4 auto-cols-auto grid-flow-col items-center">
    <div class="flex flex-col">
      <div class="mat-caption">Evento corrente:</div>
      <h1 [routerLink]="'/events/'+event.id"
          class="!text-xs sm:!text-lg cursor-pointer hover:underline">{{ event.title }}</h1>
    </div>
    <mat-divider class="h-full" vertical></mat-divider>
    <div class="flex h-full items-center space-x-2">
      <nbd-event-day-month-component [eventDate]="event.fromDate"></nbd-event-day-month-component>
      <!--    <mat-icon>arrow_right_alt</mat-icon>-->
      <!--    <nbd-event-day-month-component [eventDate]="event.toDate"></nbd-event-day-month-component>-->
    </div>
    <mat-divider class="h-full" vertical></mat-divider>
    <!--  <button mat-raised-button class="" color="accent"-->
    <!--          [disabled]="!event.guests"-->
    <!--          *ngIf="!event.enabled && !event.finished"-->
    <!--          (click)="activateEvent$.next()">-->
    <!--    ATTIVA EVENTO-->
    <!--  </button>-->
    <nbd-event-status-badge [event]="event" class="hidden md:block"></nbd-event-status-badge>
  </div>
}
