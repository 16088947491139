import {Component} from '@angular/core';
import {FieldArrayType} from '@ngx-formly/core';

@Component({
  selector: 'nbd-repeat-type',
  templateUrl: './repeat-type.component.html',
  styleUrls: ['./repeat-type.component.scss']
})
export class RepeatTypeComponent extends FieldArrayType {

}
