export const environment = {
  apiUrl: 'https://api-staging.realguest.it',
  appUrl: '',
  massMailingUrl: '',
  assetsBucketUrl: 'https://cdn-staging.realguest.it',
  checkInUrl: 'https://checkin-staging.realguest.it',
  clientUrl: 'https://app-staging.realguest.it',
  payPalClientId: 'Ad_tiph1CCnoJNDwzIrt9wYNJF_ak0nTbZ-ykKSYpxndGXPwQzkc8bFfQYj0XQ7OokA6_HJupgriPROe',
  enableDebug: true,
  reCaptchaKey: '6Le8vs0pAAAAAOdYP5OtGVLg5em2r1ZCdqV3yPxp'
};
