import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'nbd-change-password-dialog',
  templateUrl: './save-query-dialog.component.html',
  styleUrls: ['./save-query-dialog.component.scss']
})
export class SaveQueryDialogComponent {

  name?: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { name: string }) {
    this.name = data?.name;
  }
}
