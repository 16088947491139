@if (sub$ | async) {
}

<nbd-login-register-container>
  @if (loading$ | async) {
    <div class="w-full h-full">
      <nbd-loader [fill]="true" color="primary"></nbd-loader>
    </div>
  } @else {
    @if(completed) {
      <div class="w-full max-w-xs space-y-4">
        <img alt="" class="py-4" src="{{environment.assetsBucketUrl}}/img/logo.png">
        <p class="text-center text-lg">{{"common.thank-you" | translate}}</p>
        <p class="text-center" [innerHTML]="'auth.register.confirm-message' | translate"></p>
      </div>
    } @else {
      <div class="px-7 xl:pt-20 xl:pb-0 pt-20 pb-20">
        <h2 class="text-2xl font-extrabold text-[#6a39db]">{{ "auth.register.title" | translate }}</h2>
        <form [formGroup]="registerForm" class="w-full max-w-xs space-y-4 mx-auto">
          <p class="text-center">{{ "auth.register.just-registered" | translate }}
            <a class="mat-text-primary" routerLink="/auth/login">{{"common.login" | translate}}.</a>
          </p>
          <mat-form-field appearance="outline" class="w-full">
            <mat-label>{{ "auth.register.form.firstname" | translate }}</mat-label>
            <input autocomplete="given-name" formControlName="firstName" matInput type="firstName">
            @if (registerForm.controls.firstName.errors?.['required']) {
              <mat-error>
                {{ "auth.register.errors.firstname" | translate }}
              </mat-error>
            }
          </mat-form-field>
          <mat-form-field appearance="outline" class="w-full">
            <mat-label>Cognome</mat-label>
            <input autocomplete="family-name" formControlName="lastName" matInput type="lastName">
            @if (registerForm.controls.lastName.errors?.['required']) {
              <mat-error>
                {{ "auth.register.errors.lastname" | translate }}
              </mat-error>
            }
          </mat-form-field>
          <mat-form-field appearance="outline" class="w-full">
            <mat-label>{{ "common.email" | translate }}</mat-label>
            <input autocomplete="username" formControlName="email" matInput type="email">
            @if (registerForm.controls.email.errors?.['required']) {
              <mat-error>
                {{ "common.errors.email.enter" | translate }}
              </mat-error>
            }
            @if (registerForm.controls.email.errors?.['email']) {
              <mat-error>
                {{ "common.errors.email.invalid" | translate }}
              </mat-error>
            }
          </mat-form-field>
          <mat-form-field appearance="outline" class="w-full ">
            <input [placeholder]="'common.password' | translate"
              [type]="hidePassword ? 'password' : 'text'"
              autocomplete="new-password" class="text-normal bg-field"
              formControlName="password" matInput>
              <button (click)="hidePassword = !hidePassword" [attr.aria-pressed]="hidePassword"
                attr.aria-label="{{'show-password' | translate}}"
                mat-icon-button
                matSuffix>
                <mat-icon color="accent">{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
              </button>
              @if (registerForm.controls.password.errors?.['required']) {
                <mat-error
                  >{{ 'common.errors.required' | translate }}
                </mat-error>
              }
              <div class="mat-caption">
                @if (registerForm.controls.password.errors?.['minlength']) {
                  <mat-error>{{ "common.errors.password.too-short" | translate }}
                  </mat-error>
                }
                @if (registerForm.controls.password.errors?.['hasSpecialChars']) {
                  <mat-error>{{ "common.errors.password.special-char" | translate }}
                  </mat-error>
                }
                @if (registerForm.controls.password.errors?.['hasNumber']) {
                  <mat-error>{{ "common.errors.password.number" | translate }}
                  </mat-error>
                }
                @if (registerForm.controls.password.errors?.['hasUppercase']) {
                  <mat-error>{{ "common.errors.password.letter-upper" | translate }}
                  </mat-error>
                }
                @if (registerForm.controls.password.errors?.['hasLowercase']) {
                  <mat-error>{{ "common.errors.password.letter-lower" | translate }}
                  </mat-error>
                }
              </div>
            </mat-form-field>
            <mat-form-field appearance="outline" class="w-full">
              <input [placeholder]="'common.confirm-password' | translate"
                [type]="hidePassword2 ? 'password' : 'text'" autocomplete="new-password"
                class="text-normal" formControlName="confirmPassword" matInput>
                <button (click)="hidePassword2 = !hidePassword2" [attr.aria-pressed]="hidePassword2"
                  attr.aria-label="{{ 'common.confirm-password' | translate }}"
                  mat-icon-button
                  matSuffix>
                  <mat-icon color="accent">{{ hidePassword2 ? 'visibility_off' : 'visibility' }}</mat-icon>
                </button>
                @if (registerForm.controls.confirmPassword.errors?.['required']) {
                  <mat-error
                    >{{ 'common.errors.required' | translate }}
                  </mat-error>
                }
                @if (registerForm.controls.confirmPassword.errors?.['mustMatch']) {
                  <mat-error>{{ "common.errors.password.match" | translate}}
                  </mat-error>
                }
              </mat-form-field>
              <p>
                <a class="underline" href="/legal/privacy" target="_blank">{{ "auth.register.privacy" | translate}}</a>
              </p>
              <p>
                <a class="underline" href="/legal/terms" target="_blank">{{ "auth.register.terms" | translate}}</a>
              </p>
              <mat-checkbox formControlName="acceptTerms">{{ "auth.register.form.flag" | translate}}
              </mat-checkbox>
              <button (click)="register$.next()"
                [disabled]="registerForm.invalid"
                class="w-full"
                color="primary"
                mat-raised-button>
                {{ "common.register" | translate }}
              </button>
            </form>
          </div>
        }
      }
        </nbd-login-register-container>
