import {Component, Type} from '@angular/core';
import {FieldType, FormlyFieldProps} from '@ngx-formly/material/form-field';
import {FieldTypeConfig, FormlyFieldConfig} from '@ngx-formly/core';
import {MatButtonToggleAppearance} from '@angular/material/button-toggle';
import {ThemePalette} from '@angular/material/core';

interface ButtonToggleProps extends FormlyFieldProps {
  buttonToggleOptions?: Partial<{
    disabled: boolean;
    appearance: MatButtonToggleAppearance,
    vertical: boolean,
    colors: {
      [key: string]: ThemePalette
    }
  }>;
}

export interface FormlyButtonToggleFieldConfig extends FormlyFieldConfig<ButtonToggleProps> {
  type: 'button-toggle' | Type<ButtonToggleTypeComponent>;
}

@Component({
  selector: 'nbd-button-toggle-type',
  templateUrl: './button-toggle-type.component.html',
  styleUrls: ['./button-toggle-type.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonToggleTypeComponent extends FieldType<FieldTypeConfig<ButtonToggleProps>> {
  override defaultOptions = {
    props: {
      buttonToggleOptions: {
        disabled: false,
        vertical: false,
        colors: {}
      },
    },
  };
}
