import {isDevMode} from '@angular/core';
import {Observable, OperatorFunction} from 'rxjs';

export interface RxJSDebugOptions {
  trace?: boolean;
  customData?: any;
}

export interface RxJSDebugColor {
  [key: string]: {
    bg: string;
    txt: string;
  }
}

export class RxJSDebug {

  static colors: RxJSDebugColor = {
    next: {
      bg: '#84d3f5',
      txt: '#000'
    },
    error: {
      bg: '#bf1408',
      txt: '#fff'
    },
    complete: {
      bg: '#277a35',
      txt: '#fff'
    },
  }

  static output(id: string, mode: string, message?: string, value?: any, options?: RxJSDebugOptions) {
    if (isDevMode()) {
      console.log(`%c[${id} ${message}: Next]`, `background: ${RxJSDebug.colors[mode].bg}; color: ${RxJSDebug.colors[mode].txt}; padding: 3px; font-size: 9px;`,
        value ?? '');
      if (options?.trace) {
        console.trace();
      }
      if (options?.customData !== undefined) {
        console.log(options?.customData);
      }
    }
  }
}

export function debug<T>(message?: string, options?: RxJSDebugOptions): OperatorFunction<T, T> {
  return (source$: Observable<T>): Observable<T> => {
    return new Observable<T>(observer => {
      let id = '';
      if (isDevMode()) {
        id = Math.floor(Date.now() + (Date.now() * Math.random())).toString(36);
      }
      const wrapper = {
        next(value: T | undefined) {
          RxJSDebug.output(id, 'next', message, value, options);
          observer.next(value);
        },
        error(error: any) {
          RxJSDebug.output(id, 'error', message, error, options);
          observer.error(error);
        },
        complete() {
          RxJSDebug.output(id, 'complete', message, null, options);
          observer.complete();
        }
      };
      return source$.subscribe(wrapper);
    });
  };
}
