import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  Type,
  ViewChild
} from '@angular/core';
import {FieldType, FormlyFieldProps} from '@ngx-formly/material/form-field';
import {FieldTypeConfig, FormlyConfig, FormlyFieldConfig, FormlyModule} from '@ngx-formly/core';
import intlTelInput, {Iti} from 'intl-tel-input';

import {MatError, MatFormField, MatLabel, MatPrefix} from '@angular/material/form-field';
import {ReactiveFormsModule} from '@angular/forms';
import {MatInput} from '@angular/material/input';
import {DOCUMENT, isPlatformBrowser} from '@angular/common';
import {MatIcon} from '@angular/material/icon';

export interface PhoneInputProps extends FormlyFieldProps {
  telInput?: Iti
  // timepickerOptions?: Partial<{
  //   disabled: boolean;
  //   min: any;
  //   max: any;
  // }>;
}

export interface FormlyInputFieldConfig extends FormlyFieldConfig<PhoneInputProps> {
  type: 'phone' | Type<PhoneTypeComponent>;
}

@Component({
  selector: 'nbd-phone-type',
  templateUrl: './phone-type.component.html',
  styleUrl: './phone-type.component.scss'
})
export class PhoneTypeComponent extends FieldType<FieldTypeConfig<PhoneInputProps>> implements OnInit, OnDestroy {

  telInput?: any;
  // phoneUtil = new PhoneNumber();
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    super();
  }

  @ViewChild('phoneInput', {read: ElementRef}) set phoneInput(phoneInput: ElementRef) {
    if (phoneInput && isPlatformBrowser(this.platformId)) {
      // this.field.validators.ù ddValidators(
      //   control => {
      //     return this.phoneUtil.checkNumber(this.telInput?.getNumber()).valid ? null : {phone: true};
      //   }
      // );

      this.telInput = intlTelInput(phoneInput.nativeElement, {
        utilsScript: './assets/js/tel/utils.js',
        // containerClass: 'tel-input-bg',
        initialCountry: 'IT',
        nationalMode: true,
        autoPlaceholder: "off",
        // @ts-ignore
        strictMode: true,
        // @ts-ignore
        // hiddenInput: () => ({ phone: "phone", country: "country_code" }),
        // dropdownContainer: this.flagsContainer?.nativeElement
        dropdownContainer: document.body
        // autoInsertDialCode: false,
        // formatOnDisplay: true
      });

      this.telInput.promise.then(() => {
        if (this.formControl.value) {
          this.telInput.setNumber(this.formControl.value);
          // this.formControl.patchValue(this.telInput.getNumber(intlTelInputUtils.numberFormat.NATIONAL));
        }

        this.form.updateValueAndValidity();
      });

      this.props.telInput = this.telInput;

      // this.formControl.valueChanges.subscribe((event) => {
      //   console.log(this.formControl.getRawValue())
      // });
    }
  }



  ngOnInit() {

  }

  override ngOnDestroy() {
    super.ngOnDestroy();
  }


}
