import {Component, OnInit} from '@angular/core';
import {FieldType} from '@ngx-formly/material/form-field';
import {FieldTypeConfig} from '@ngx-formly/core';
import {Observable, startWith, switchMap} from 'rxjs';

@Component({
  selector: 'nbd-autocomplete-type',
  templateUrl: './autocomplete-type.component.html',
  styleUrls: ['./autocomplete-type.component.scss']
})
export class AutocompleteTypeComponent extends FieldType<FieldTypeConfig> implements OnInit {

  filter?: Observable<any>;

  ngOnInit() {
    this.filter = this.formControl.valueChanges.pipe(
      startWith(''),
      switchMap((term) => this.props['filter'](term)),
    );
  }
}
