import {Component} from '@angular/core';
import {FieldType} from '@ngx-formly/core';

@Component({
  selector: 'nbd-null-type',
  templateUrl: './null-type.component.html',
  styleUrls: ['./null-type.component.scss']
})
export class NullTypeComponent extends FieldType {
}
