import {Component} from '@angular/core';
import {BehaviorSubject, catchError, Observable, of, Subject, switchMap, tap} from 'rxjs';
import {FormBuilder, Validators} from '@angular/forms';
import {AuthService} from '../auth.service';
import {Router} from '@angular/router';
import {debug} from '../../shared/rxjs/debug';
import {HelperService} from '../../shared/helper.service';
import {BaseRxjsComponent} from '../../shared/components/BaseRxjsComponent';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'nbd-request-url-login',
  templateUrl: './request-url-login.component.html',
  styleUrls: ['./request-url-login.component.scss']
})
export class RequestUrlLoginComponent extends BaseRxjsComponent {

  login$ = new Subject<void>();
  error$ = new BehaviorSubject<string>('');

  loginForm = this.fb.nonNullable.group({
    email: ['', [Validators.required, Validators.email]]
  });

  hidePassword = true;
  completed = false;

  constructor(private auth: AuthService, private fb: FormBuilder, private router: Router, private hs: HelperService) {
    super();

    this.sub = this.login$.pipe(
      tap(() => {
        this.loading$.next(true);
        this.error$.next('');
      }),
      switchMap(() => this.auth.emailLoginRequest(this.loginForm.getRawValue()).pipe(
        debug('Login'),
        catchError((error) => {
          this.error$.next(error.error.description);
          // if (error.error.description === 'invalidCredentials') {
          //   // this.error$.next('Email già registrata');
          //   this.hs.error('Errore', 'Questa email non è registrata');
          // } else {
          //   this.hs.error('Errore', 'Si è verificato un\'errore');
          //   // this.error$.next('Errore di registrazione');
          // }
          // if (error.description === 'userInactive') {
          //   this.errorMessage = error.message;
          //   this.activationLink = true;
          //   this.sendActivationTo = this.usernameFormControl.value;
          // } else {
          //   this.errorMessage = error.message ? error.message : trans('auth.login.loginError');
          // }
          // this.error = true;
          return of(null);
        }),
        tap((data) => {
          this.loading$.next(false);
          if (data) {
            this.completed = true;
          }
          // this.router.navigate(['/'])

          // if (data !== null) {
          //   const u = data;
          //
          //   if (u.role === 'admin') {
          //     this.router.navigate(['/']);
          //     return;
          //   }
          // }

        })
      ))
    ).subscribe();
  }

  protected readonly environment = environment;
}
