import {Component} from '@angular/core';
import {ActivationStart, Router} from '@angular/router';
import {AutoUnsubscribe} from './shared/classes/AutoUnsubscriber';
import {filter} from 'rxjs/operators';
import {tap} from 'rxjs';
import {environment} from '../environments/environment';
import {TranslateService} from '@ngx-translate/core';

@AutoUnsubscribe()
@Component({
  selector: 'nbd-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  directNavigation = false;

  constructor(private router: Router, private ts: TranslateService) {
    ts.setDefaultLang('it');

    environment.appUrl = `${environment.apiUrl}/app`;
    (this as any).sub = this.router.events.pipe(
      filter((data) => data instanceof ActivationStart),
      tap((data) => {
        data = data as ActivationStart;
        this.directNavigation = data.snapshot.data['directNavigation'] ?? false;
      })
    ).subscribe();
  }
}
