import {CheckedNumber} from './phone-item';
import {PhoneNumberFormat as PNF, PhoneNumberUtil} from 'google-libphonenumber';

export class PhoneNumber {

  phoneUtil = PhoneNumberUtil.getInstance();

  checkNumber(numberString: string): CheckedNumber {
    const res = {
      valid: false,
      number: numberString,
      country: '--'
    }
    if (numberString && numberString.length > 0 && numberString !== 'null') {
      if (numberString.startsWith('00')) {
        numberString = numberString.replace('00', '+');
      }
      try {
        const number = this.phoneUtil.parseAndKeepRawInput(numberString);
        if (this.phoneUtil.isValidNumber(number)) {
          res.number = this.phoneUtil.format(number, PNF.E164);
          res.country = this.phoneUtil.getRegionCodeForNumber(number) ?? '--';
          res.valid = true;
        }
      } catch (exception: any) {
        console.log(exception);
        res.number = 'ERRORE PARSING';
      }

    } else {
      res.number = 'NUMERO NON VALIDO';
    }


    return res;
  }
}
