import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'toNull'
})
export class ToNullPipe implements PipeTransform {

  transform(value: string | null, ...args: unknown[]): string | null {
    if (value === null || (value as string).toLowerCase() === 'null') {
      return null;
    }

    return value;
  }

}
