import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'null'
})
export class NullPipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): unknown {
    if (value === null || (value as string).length === 0 || value.toString().toLowerCase() === 'null') {
      return "(vuoto)";
    }

    return value;
  }

}
