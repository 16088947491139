import {Directive, ElementRef, Input, Renderer2, ViewContainerRef} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Subject, switchMap, tap} from 'rxjs';
import {MatButton} from '@angular/material/button';
import {MatProgressBar} from '@angular/material/progress-bar';
import {HelperService} from '../helper.service';

@Directive({
  selector: '[nbdDownloadFile]'
})
export class DownloadFileDirective {

  @Input() downloadLink!: string;

  download$ = new Subject<void>();

  private progressBar: any;

  constructor(private http: HttpClient,
              private hs: HelperService,
              private viewContainerRef: ViewContainerRef,
              private matButton: MatButton,
              private renderer: Renderer2,
              private elementRef: ElementRef) {

    this.download$.pipe(
      tap(() => this.loading()),
      switchMap(() => this.http.get<{ fileName: string; url: string; }>(this.downloadLink).pipe(
        this.hs.httpErrorHandler(null)
      )),
      tap((data) => {
        if (data) {
          const link = document.createElement("a");
          link.href = data.url;
          link.download = data.fileName;
          link.click();
        }

        this.loaded();
      })
    ).subscribe();

    this.viewContainerRef.clear();
    const matProgress = this.viewContainerRef.createComponent(MatProgressBar);
    matProgress.instance.mode = 'indeterminate';
    this.progressBar = matProgress.injector.get(MatProgressBar)._elementRef.nativeElement
    this.progressBar.style.position = 'absolute';
    this.progressBar.style.bottom = '0';
    this.renderer.appendChild(this.elementRef.nativeElement, this.progressBar);
    // this.elementRef.nativeElement.classList.add('w-full');
    this.elementRef.nativeElement.addEventListener('click', (event: Event) => {
      this.download$.next();
    });
    this.loaded();
  }

  loading() {
    this.progressBar.style.opacity = '0.7';
    this.matButton.disabled = true;
  }

  loaded() {
    this.progressBar.style.opacity = '0';
    this.matButton.disabled = false;
  }

}
