<mat-button-toggle-group [formControl]="formControl"
  [formlyAttributes]="field"
  [id]="id"
  [required]="required">
  @for (option of props.options | formlySelectOptions : field | async; track option; let i = $index) {
    <mat-button-toggle #button
      [disabled]="option.disabled === true"
      [id]="id + '_' + i"
                     [ngClass]="{
                     'mat-full-primary': formControl.value === option.value && props.buttonToggleOptions?.colors?.[option.value.toString()] === 'primary',
                     'mat-full-accent': formControl.value === option.value && props.buttonToggleOptions?.colors?.[option.value.toString()] === 'accent',
                     'mat-full-warn': formControl.value === option.value && props.buttonToggleOptions?.colors?.[option.value.toString()] === 'warn'
                     }"
      [value]="option.value">
      {{ option.label }}
    </mat-button-toggle>
  }
</mat-button-toggle-group>
