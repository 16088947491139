import {AbstractControl, ValidationErrors} from '@angular/forms';
import {FormlyFieldConfig} from '@ngx-formly/core';

export abstract class VatValidator {
  static validator(control: AbstractControl): ValidationErrors | null {
    return !control.value || /[0-9]{11}/.test(control.value) ? null : {vat: true};
  }

  static message(error: any, field: FormlyFieldConfig) {
    return `Partita IVA non valida`;
  }
}
