<!--<div class="flex flex-col mat-text-primary-contrast items-center border rounded h-full w-12 justify-center" *ngIf="eventDate">-->
<!--  <div class="-mb-1">{{eventDate | isoToDate | date:'dd'}}</div>-->
<!--  <div class="text-xs">{{eventDate | isoToDate | date:'MMM'}}</div>-->
<!--</div>-->
@if(eventDate) {
  <div [style.background-image]="'url('+environment.assetsBucketUrl+'/img/calendar.svg)'"
       class="flex flex-col items-center h-full w-12 justify-center bg-center bg-no-repeat text-gray-800">
    <div class="text-base sm:text-lg -mb-1 -mt-1"><strong>{{ eventDate | isoToDate | date:'dd' }}</strong></div>
    <div class="text-xs -mt-1">{{ eventDate | isoToDate | date:'MMM' }}</div>
  </div>
}
