import {Component} from '@angular/core';
import {FieldType} from '@ngx-formly/core';

@Component({
  selector: 'nbd-separator-type',
  templateUrl: './separator-type.component.html',
  styleUrls: ['./separator-type.component.scss']
})
export class SeparatorTypeComponent extends FieldType {
}
