import {AbstractControl, ValidationErrors} from '@angular/forms';

export class MustMatchValidator {

  static mustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: AbstractControl): ValidationErrors | null => {
      const control = formGroup.get(controlName);

      const matchingControl = formGroup.get(matchingControlName);

      if (matchingControl?.errors && !matchingControl.errors['mustMatch']) {
        // return if another validator has already found an error on the matchingControl

        return null;
      }

      // return error if validation fails

      let errors = null;
      if (control?.value !== matchingControl?.value) {
        errors = {mustMatch: true};
      }
      matchingControl?.setErrors(errors);
      return errors;
    };
  }

}
