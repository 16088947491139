import {Component, Input} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {SharedModule} from '../../../shared/shared.module';
import {DatePipe} from '@angular/common';

@Component({
  standalone: true,
  selector: 'nbd-event-day-month-component',
  templateUrl: './event-day-month-component.component.html',
  imports: [
    SharedModule,
    DatePipe
  ],
  styleUrls: ['./event-day-month-component.component.scss']
})
export class EventDayMonthComponentComponent {
  @Input() eventDate?: string;
  protected readonly environment = environment;
}
