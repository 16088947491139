import {Component} from '@angular/core';
import {AuthService} from '../auth.service';

@Component({
  selector: 'nbd-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent {
  constructor(public auth: AuthService) {
  }
}
