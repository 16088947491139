import {Component, OnDestroy, ViewChild} from '@angular/core';
import {HelperService} from '../../shared/helper.service';
import {CoreService} from '../core.service';
import {AuthService} from '../../auth/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {ToolbarMenuHostDirective} from '../classes/toolbar-menu-host.directive';
import {combineLatestWith, distinctUntilChanged, ReplaySubject, switchMap, tap} from 'rxjs';
import {GlobalLoaderService} from '../../shared/global-loader.service';
import {MatExpansionPanel} from '@angular/material/expansion';
import {BaseRxjsComponent} from '../../shared/components/BaseRxjsComponent';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'nbd-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent extends BaseRxjsComponent implements OnDestroy {

  private toolbar$ = new ReplaySubject<ToolbarMenuHostDirective>(1);
  private userMenu$ = new ReplaySubject<MatExpansionPanel>(1);

  constructor(public hs: HelperService,
              public cs: CoreService,
              public gl: GlobalLoaderService,
              public auth: AuthService,
              private location: Location,
              private route: ActivatedRoute,
              private router: Router) {

    super();

    this.sub = this.hs.toolbarComponent$.pipe(
      combineLatestWith(this.toolbar$.pipe(
        distinctUntilChanged()
      )),
      tap(([component, toolbar]) => {
        const viewContainerRef = toolbar.viewContainerRef;
        viewContainerRef.clear();
        if (component) {
          const componentRef = viewContainerRef.createComponent<typeof component.component>(component.componentType);
          componentRef.instance.data = component.data;
        }
      })
    ).subscribe();

    this.sub = this.cs.userMenuExpanded$.pipe(
      switchMap((expanded) => this.userMenu$.pipe(
        tap((userMenu) => expanded ? userMenu.open() : userMenu.close())
      )),
    ).subscribe();


  }

  @ViewChild(ToolbarMenuHostDirective) set toolbarMenuHost(value: ToolbarMenuHostDirective) {
    if (value) {
      this.toolbar$.next(value);
    }
  }

  @ViewChild(MatExpansionPanel) set userMenuHost(value: MatExpansionPanel) {
    if (value) {
      this.userMenu$.next(value);
    }
  }

  // toggleMenu() {
  //   this.collapseMenu = !this.collapseMenu;
  // }

  back(): void {
    this.cs.navHistory.pop();
    if (this.cs.navHistory.size() > 0) {
      this.location.back();
    } else if (this.hs.backBtnFallback) {
      this.router.navigate(this.hs.backBtnFallback);
      this.hs.backBtnFallback = undefined;
    } else {
      this.router.navigateByUrl('/');
    }
  }

  protected readonly environment = environment;
}
