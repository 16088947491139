<div class="h-full flex items-center">
  <div class="container text-center pl23-login-card">
    <div class="row">
      <div class="col">
        <span class="mat-headline-6 text-normal">{{ 'auth.new-password.title' | translate }}</span><br>
      </div>
    </div>
    <div class="row">
      <div class="col">
        @if (!success) {
          <span class="mat-body-1 text-normal">"{{ 'auth.new-password.not-success' | translate }}"</span>
        }
        @if (error && !success) {
          <h6 class="mat-error text-center">{{ 'auth.new-password.error' | translate }}</h6>
        }
        @if (success) {
          <span class="mat-body-1 text-normal">{{ 'auth.new-password.success' | translate }}</span>
        }
      </div>
    </div>
    @if (!success) {
      <form [formGroup]="newPwdForm">
        <div class="row mt-4">
          <div class="col">
            <mat-form-field appearance="outline" class="w-100 my-1 pwd-field">
              <input [type]="hidePassword ? 'password' : 'text'"
                     class="text-normal"
                     formControlName="password"
                     matInput [placeholder]="'common.password' | translate">
              <button (click)="hidePassword = !hidePassword" [attr.aria-pressed]="hidePassword"
                      mat-icon-button
                      matSuffix>
                <mat-icon color="accent">{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
              </button>
              <div class="mat-caption">
                @if (f.password.errors?.['minlength']) {
                  <mat-error>{{ 'common.errors.password.too-short' | translate }}</mat-error>
                }
                @if (f.password.errors?.['hasSpecialChars']) {
                  <mat-error>{{ 'common.errors.password.special-char' | translate }}</mat-error>
                }
                @if (f.password.errors?.['hasNumber']) {
                  <mat-error>{{ 'common.errors.password.number' | translate }}</mat-error>
                }
                @if (f.password.errors?.['hasUppercase']) {
                  <mat-error>{{ 'common.errors.password.letter-upper' | translate }}</mat-error>
                }
                @if (f.password.errors?.['hasLowercase']) {
                  <mat-error>{{ 'common.errors.password.letter-lower' | translate }}</mat-error>
                }
              </div>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-form-field appearance="outline" class="w-100 mt-1 mb-3 pwd-field">
              <input [type]="hidePassword2 ? 'password' : 'text'"
                     class="text-normal"
                     formControlName="confirmPassword" matInput [placeholder]="'common.confirm-password' | translate">
              <button (click)="hidePassword2 = !hidePassword2" [attr.aria-pressed]="hidePassword2"
                      mat-icon-button
                      matSuffix>
                <mat-icon color="accent">{{ hidePassword2 ? 'visibility_off' : 'visibility' }}</mat-icon>
              </button>
              <div class="mat-caption">
                <!--              <mat-error *ngIf="f.confirmPassword.errors?.['required']">Campo obbligatorio</mat-error>-->
                @if (f.confirmPassword.errors?.['mustMatch']) {
                  <mat-error>{{ 'common.errors.password.match' | translate }}</mat-error>
                }
              </div>
            </mat-form-field>
          </div>
        </div>
      </form>
    }

    <div class="row">
      <div class="col">
        @if (!success) {
          <button (click)="changePassword()" [disabled]="!newPwdForm.valid" class="mt-4" color="accent"
                  mat-flat-button>{{'common.edit' | translate}}
          </button>
        }
        @if (success) {
          <button class="mt-4" color="accent" mat-flat-button
                  routerLink="/auth/login">{{'common.login' | translate}}
          </button>
        }
      </div>
    </div>
  </div>
</div>
