import {Component, Input} from '@angular/core';

@Component({
  selector: 'nbd-loading-box',
  templateUrl: './loading-box.component.html',
  styleUrls: ['./loading-box.component.scss']
})
export class LoadingBoxComponent<T> {
  @Input() loading?: boolean | null = false;
  @Input() item?: T | null;
}
