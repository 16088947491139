import {Subscription} from 'rxjs';

interface AutoUnsubscribeConfig {
  componentAlias?: string;
  enableDebug?: boolean;
}

export function AutoUnsubscribe(config?: AutoUnsubscribeConfig) {
  return (target: any) => {
    const componentName = config?.componentAlias ?? target.name ?? 'unknown';
    const sub = new Subscription();

    const subLog = function (str: string) {
      if (config?.enableDebug) {
        console.log(str);
      }
    }

    subLog(`${componentName}: INIT`);

    const getter = function () {
      subLog(`${componentName}: GET sub`);
      return sub;
    };
    const setter = function (sub: Subscription) {
      subLog(`${componentName}: SET sub`);
      sub.add(sub);
    };

    Object.defineProperty(target.prototype, 'sub', {
      get: getter,
      set: setter
    });

    const original = target.prototype.ngOnDestroy;
    target.prototype.ngOnDestroy = function () {
      subLog(`${componentName}: UNSUBSCRIBE!`);
      this.sub?.unsubscribe();
      original?.apply(this, arguments);
    };
  }


  // const sub = new Subscription();
  // const getter = function() {
  //   return sub;
  // };
  // const setter = function(sub: Subscription) {
  //   sub.add(sub);
  // };
  //
  // Object.defineProperty(target, 'sub', {
  //   get: getter,
  //   set: setter
  // });
  //
  // const original = target.prototype.ngOnDestroy;
  // target.prototype.ngOnDestroy = function () {
  //   this.sub?.unsubscribe();
  //   original?.apply(this, arguments);
  // };

}
