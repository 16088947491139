<!--<ng-container *ngIf="m">-->
<!--  <mat-list-item *ngIf="m.visible"-->
<!--                 [activated]="m.isActive"-->
<!--                 [routerLink]="[m.url]">-->
<!--    <mat-icon matListItemIcon>{{m.icon}}</mat-icon>-->
<!--    <a matListItemTitle [innerHTML]="m.label"></a>-->
<!--  </mat-list-item>-->
<!--</ng-container>-->
@if(m && m.visible) {
  @if (m.mode === 'button') {
    <div class="w-full h-[48px] px-4 grid items-center">
      @if (m && m.visible) {
        <button (click)="handleClick($event)"
                [color]="m.color"
                [ngClass]="m.class"
                class="w-full"
                mat-raised-button>
          @if (m.imageType === 'icon') {
            <mat-icon>{{ m.icon }}</mat-icon>
          }
          @if (m.imageType === 'image') {
            <img
              [alt]="m.label" [src]="m.icon"
              class="menu-image" matListItemIcon>
          }
          <span>{{ m.label }}</span>
        </button>
      }
    </div>
  } @else {
    <mat-expansion-panel (click)="handleClick($event)"
                         [expanded]="m.isActive && !!m.children?.length"
                         [hideToggle]="!m.isActive || !m.children?.length"
                         class="mat-elevation-z0 menu-item">
      <mat-expansion-panel-header class="!h-auto !pl-0 z-10 !rounded-none"
                                  [class.mat-elevation-z2]="!!m.children?.length">
        <div class="px-4 py-3 grid grid-cols-[max-content_1fr] items-center gap-2">
          @switch (m.imageType) {
            @case ('icon') {
              <mat-icon [class.mat-text-primary]="!m.color && m.isActive"
                        [color]="m.color"
                        matListItemIcon>{{ m.icon }}
              </mat-icon>
            }
            @case ('image') {
              <img [alt]="m.label" [src]="m.icon"
                   class="menu-image" matListItemIcon>
            }
            @case ('none') {
            }
          }
          @if (!collapsed) {
            <div class="flex flex-col overflow-hidden">
                <span [class.font-bold]="m.isActive"
                      [class.mat-text-primary]="!m.color && m.isActive"
                      [ngClass]="m.color ? 'mat-text-' + m.color : ''"
                      class="text-ellipsis whitespace-nowrap overflow-hidden">{{ m.label }}</span>
            </div>
          }
        </div>
        @if (!collapsed) {
          @if (m.badgeCounter; as counter) {
            <div class="ml-auto mat-caption menu-badge-counter self-center mr-4">
              <span>{{ counter }}</span>
            </div>
          }
        }
      </mat-expansion-panel-header>
      @if (m.children?.length) {
        <div class="pl-4 pb-4">
          @for (i of m.children; track i) {
            <nbd-nav-menu-item
              class="left-border"
              [collapsed]="collapsed"
              [drawer]="drawer"
              [isHandset]="isHandset"
              [m]="i">
            </nbd-nav-menu-item>
          }
        </div>
      }
    </mat-expansion-panel>
  }
}
