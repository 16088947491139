import {Component, OnInit, Type} from '@angular/core';
import {FieldArrayType, FieldTypeConfig, FormlyFieldConfig} from '@ngx-formly/core';
import {FormlyFieldProps} from '@ngx-formly/material/form-field';

export interface RepeatProps extends FormlyFieldProps {
  wrapWithCard: boolean;
  actionLabel: string;
}

export interface FormlyInputFieldConfig extends FormlyFieldConfig<RepeatProps> {
  type: 'repeat' | Type<RepeatTypeComponent>;
}

@Component({
  selector: 'nbd-repeat-type',
  templateUrl: './repeat-type.component.html',
  styleUrls: ['./repeat-type.component.scss']
})
export class RepeatTypeComponent extends FieldArrayType<FieldTypeConfig<RepeatProps>> implements OnInit {

  ngOnInit() {
    if (!this.props.actionLabel) {
      this.props.actionLabel = "Aggiungi";
    }
  }

}
