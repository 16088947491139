export enum ColumnType {
  Default,
  Boolean,
  Date,
  Time,
  DateTime,
  Array,
  ID,
  JSON,
  JSONArray
}
