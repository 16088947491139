import {Component, Input} from '@angular/core';

@Component({
  selector: 'nbd-detail-stacked-item',
  templateUrl: './detail-stacked-item.component.html',
  styleUrls: ['./detail-stacked-item.component.scss']
})
export class DetailStackedItemComponent {
  @Input() title?: any;
  @Input() value?: any;
  @Input() align: 'start' | 'end' = 'start';
  @Input() suffix = '';
}
