import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DialogData} from '../../../models/dialog-data';

@Component({
  selector: 'nbd-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
  confirmationCode?: string;
  userConfirmationCode?: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {

  }

  ngOnInit(): void {
    if(this.data.useConfirmationCode) {
      this.confirmationCode = (Math.floor(Math.random() * (99999 - 10000 + 1)) + 10000).toString();
    }
  }
}
