import {Component} from '@angular/core';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'nbd-login-register-container',
  templateUrl: './login-register-container.component.html',
  styleUrls: ['./login-register-container.component.scss']
})
export class LoginRegisterContainerComponent {

  protected readonly environment = environment;
}
