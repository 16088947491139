import {Component, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';

import {Router} from '@angular/router';
import {BaseRxjsComponent} from "../../shared/components/BaseRxjsComponent";
import {AuthService} from "../auth.service";
import {BehaviorSubject, catchError, of, Subject, switchMap, tap} from "rxjs";
import {debug} from "../../shared/rxjs/debug";
import {loading} from "../../shared/rxjs/loading";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'tr-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent extends BaseRxjsComponent implements OnInit {

  email = new FormControl('', [Validators.required, Validators.email]);
  sent = false;
  forgotPassword$ = new Subject<void>();
  error$ = new BehaviorSubject<string>('');

  constructor(private as: AuthService,
              private ts: TranslateService) {
    super();

    this.sub = this.forgotPassword$.pipe(
      loading(this),
      switchMap(() => this.as.passwordReset(this.email.value!).pipe(
        debug('Forgot Password'),
        catchError((data) => {
            switch (data.error.description) {
              case 'invalidEmail':
                this.error$.next(this.ts.instant('server.errors.invalidEmail'));
                break;
              default:
                this.error$.next(this.ts.instant('server.common.unknownError'));
            }
            return of(null);
          }),

        tap((data) => {
          this.loaded();
          if (data) {
            this.sent = true;
          }
        })
      ))
    ).subscribe();

  }

  ngOnInit(): void {
  }





    // this.as.passwordReset(this.email.value!).toPromise().then(
    //   (data) => {
    //     this.sent = true;
    //     this.loaded();
    //   },
    //   (err) => {
    //     this.loaded();
    //     let message = 'auth.forgotPassword.failed';
    //     if (err.error?.description) {
    //       message = 'auth.forgotPassword.' + err.error.description;
    //     }
    //     this.router.navigate(['/error'], {queryParams: {m: message}});
    //   }
    // );


}
