import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'boolean'
})
export class BooleanPipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): unknown {

    if (value === undefined) {
      return null;
    }

    if (value === null || value === 'NULL') {
      return "(vuoto)";
    }

    const val = JSON.parse(value);


    if (typeof (val) === 'number') {
      if (val > 0) {
        return 'Sì';
      }
      if (val === 0) {
        return 'No';
      }
      if (val < 0) {
        return 'Indeterminato';
      }
    }

    return Boolean(val) ? 'Sì' : 'No';
  }

}
