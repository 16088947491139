import {Component} from '@angular/core';

@Component({
  selector: 'nbd-email-confirmed',
  templateUrl: './email-confirmed.component.html',
  styleUrls: ['./email-confirmed.component.scss']
})
export class EmailConfirmedComponent {

}
