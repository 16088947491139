import {Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {
  BehaviorSubject,
  distinctUntilChanged,
  Observable,
  of,
  ReplaySubject,
  share,
  Subject,
  Subscription,
  switchMap,
  tap
} from 'rxjs';
import {ColumnConfig} from './models/ColumnConfig';
import {MatSort, Sort} from '@angular/material/sort';
import {PageEvent} from '@angular/material/paginator';
import {CrmDataSource} from './models/CrmDataSource';
import {MatListOption} from '@angular/material/list';
import {ColumnType} from './models/ColumnType';
import {map, shareReplay} from 'rxjs/operators';
import {debug} from '../rxjs/debug';
import {data} from 'autoprefixer';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {HelperService} from '../helper.service';
import {SelectionModel} from '@angular/cdk/collections';
import {AuthService} from "../../auth/auth.service";

@Component({
  selector: 'nbd-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit, OnDestroy {

  @Input() config: ColumnConfig[] = [];
  @Input() dataSource!: CrmDataSource;
  @Input() navigationColumn: boolean = false;
  @Input() navigationUrl?: string;
  @Input() navigationId?: string;

  @Input() allowSelection = false;
  @Input() allowColumnSelection = false;
  @Input() showExportButton = true;

  @Output() selectedItems = new EventEmitter<any[]>();
  sub = new Subscription();
  viewInitialized$ = new Subject<void>();
  export$ = new Subject<void>();
  sort$ = new Subject<MatSort | null>();
  selectionEnabled$: Observable<boolean>;
  toggleSelection$ = new BehaviorSubject<boolean>(false);
  groupsFilterList$: Observable<string[]>;
  selectGroupFilter$ = new ReplaySubject<string | null>(1);
  columnType = ColumnType;
  currentResizingColumn: string | null = null;
  public selectedItemsMap = new Map<any, any>();
  protected readonly data = data;
  private initialWidth: number = 0;
  private initialMousePosition: number = 0;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private location: Location,
              public hs: HelperService,
              public auth: AuthService) {

    this.groupsFilterList$ = this.selectGroupFilter$.pipe(
      switchMap((columnName) => {
        if (columnName) {
          return this.dataSource.getGroup(columnName)
        }
        return of([]);
      }),
      map((groups) => {
        groups.forEach((g, i) => {
          if (g === null) {
            groups[i] = 'NULL'
          }
        });
        return groups;
      }),
      debug('Group'),
      shareReplay(1)
    );

    this.selectionEnabled$ = this.toggleSelection$.pipe(
      tap((enabled) => {
        if (!enabled) {
          this.selectedItemsMap.clear();
          this.selectedItems.emit([]);
        }
      }),
      share()
    );


  }

  @ViewChild(MatSort) set sort(value: MatSort) {
    this.sort$.next(value);
  }

  ngOnInit(): void {
    this.route.queryParamMap.pipe(
      map((params) => params.get('q')),
      distinctUntilChanged(),
      debug('Query'),
      tap((query) => {
        if (query) {
          const q = this.dataSource.setQuery(query);
          if (q.allColumns) {
            this.dataSource.selectedColumns$.next(this.config.filter(c => !c.isIndex).map(c => c.columnName));
          }
          this.config.forEach(c => c.visible = !!q.allColumns || q.selectedColumns?.includes(c.columnName) || false);
        } else {
          this.resetDataSource();
        }
      })
    ).subscribe();

    this.dataSource.query$.pipe(
      debug('DS Query'),
      tap((q) => {
        const urlTree = this.router.createUrlTree([], {
          queryParams: {q},
          queryParamsHandling: 'merge',
          preserveFragment: true
        });
        this.location.go(urlTree.toString())
      })
    ).subscribe();

    this.sub.add(this.dataSource.data$.pipe(
      tap((data) => {
        this.toggleSelection$.next(false);
      })
    ).subscribe());

    this.sub.add(this.export$.pipe(
      switchMap(() => this.dataSource.export()),
      debug('Export'),
      tap((data) => {
        if (data) {
          const link = document.createElement("a");
          link.href = data.url;
          link.download = data.fileName;
          link.click();
        }

      })
      // tap((file) => this.fs.save(file, 'Users.xlsx') )
    ).subscribe());

  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  startResize(column: string, event: MouseEvent) {
    this.currentResizingColumn = column;
    const columnHeader = document.querySelector(
      `.mat-column-${this.currentResizingColumn}`
    ) as HTMLElement;
    this.initialWidth = columnHeader.getBoundingClientRect().width ?? 0;
    this.initialMousePosition = event.clientX;

    // console.log(`START | IW: ${this.initialWidth}, IMP: ${this.initialMousePosition}`);
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (this.currentResizingColumn) {
      const columnHeader = document.querySelector(
        `.mat-column-${this.currentResizingColumn}`
      ) as HTMLElement;

      if (columnHeader) {
        const newWidth = Math.max(this.initialWidth + (event.clientX - this.initialMousePosition), 200);
        columnHeader.style.width = `${newWidth}px`;
      }
    }
  }

  @HostListener('document:mouseup', ['$event'])
  onMouseUp(event: MouseEvent) {
    if (this.currentResizingColumn) {
      setTimeout(() => this.currentResizingColumn = null, 0);
      event.preventDefault();
      event.stopPropagation();
    }
  }

  selectAll(visible: boolean) {
    this.config.forEach(c => {
      if (!c.isIndex && !c.notInTable && !c.disabledFor?.includes(this.auth.role())) {
        c.visible = visible
      }
    });
    this.setSelectedColumns();
  }

  setPageData(e: PageEvent) {
    this.dataSource.page$.next(e.pageIndex);
    this.dataSource.pageSize$.next(e.pageSize);
  }

  setSortData(e: Sort) {
    if (e.direction === "") {
      this.dataSource.sort$.next(null);
    } else {
      this.dataSource.sort$.next({
        columnName: e.active,
        sortDirection: e.direction
      });
    }
  }

  getTextFilter(columnName: string): string {
    return this.dataSource.columnFilters$.value.find(x => x.columnName === columnName)?.textFilter ?? '';
  }

  sendItemsSelection(columnName: string, selected: SelectionModel<MatListOption>) {
    this.dataSource.itemsFilter(columnName, selected.selected.map(o => o.value ?? 'NULL'))
  }

  isGroupOptionSelected(columnName: string, value: string): boolean {
    return this.dataSource.columnFilters$.value.find(x => x.columnName === columnName)?.columnItems.find(i => i === value) === value;
  }

  isGroupFilterActive(columnName: string) {
    return (this.dataSource.columnFilters$.value.find(x => x.columnName === columnName)?.columnItems?.length ?? 0) > 0;
  }

  setSelectedColumns() {
    const items = this.config.filter(x => x.visible);
    if (items.length > 0) {
      const selectedColumns = items.map(x => x.columnName);
      const filters = this.dataSource.columnFilters$.value.filter(x => selectedColumns.includes(x.columnName));
      this.dataSource.selectedColumns$.next(selectedColumns);
      this.dataSource.columnFilters$.next(filters);
    }
  }

  addOrRemoveItem(selected: boolean, id: any) {
    this.setSelectedState(selected, id);

    this.selectedItems.next(Array.from(this.selectedItemsMap.keys()));
  }

  toggleSelectAll(selected: boolean) {
    for (const i of this.dataSource.data$.value.items) {
      this.setSelectedState(selected, i[this.navigationId ?? 'id']);
    }

    this.selectedItems.next(Array.from(this.selectedItemsMap.keys()));
  }

  log(event: any) {
    console.log(event);
  }

  private resetDataSource() {
    this.dataSource.reset(this.config);
  }

  private setSelectedState(selected: boolean, id: any) {
    if (selected) {
      this.selectedItemsMap.set(id, null);
    } else {
      this.selectedItemsMap.delete(id);
    }
  }
}
