import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'dotToValue'
})
export class DotToValuePipe implements PipeTransform {

  transform(value: any, path: string): unknown {
    return path.split('.').reduce((v, property) => v?.[property], value);
  }

}
