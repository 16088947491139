<mat-card>
  <mat-card-content>
    @if(loading$ | async) {
      <nbd-loader color="primary"></nbd-loader>
    } @else {
      <div class="grid grid-cols-1">
        <form [formGroup]="loginForm" class="w-full max-w-xs space-y-4">

          <img alt="" class="py-4" src="{{environment.assetsBucketUrl}}/img/logo.png">

          @if (!completed) {
            <mat-form-field appearance="fill" class="w-full">
              <mat-label>Email</mat-label>
              <input autocomplete="username" formControlName="email" matInput type="email">
              @if (loginForm.controls.email.errors?.['required']) {
                <mat-error>
                  Inserisci un'email
                </mat-error>
              }
              @if (loginForm.controls.email.errors?.['email']) {
                <mat-error>
                  Indirizzo email non valido
                </mat-error>
              }
            </mat-form-field>
            @if (error$ | async; as error) {
              @if (error.length > 0) {
                <div class="mat-bg-warn text-white text-center rounded p-2">
                  @if (error === 'invalidCredentials') {
                    Questa email non è registrata.
                    Effettua la registrazione per giocare.
                  } @else {
                    Errore di login
                  }
                </div>
              }
            }
            <button (click)="login$.next()"
              [disabled]="loginForm.invalid"
              class="w-full"
              color="primary"
              mat-raised-button>
              Login
            </button>
            <p class="text-center">oppure</p>
            <button class="w-full"
              color="accent"
              mat-flat-button
              routerLink="/auth/register">
              Registrati
            </button>
            <div class="flex justify-end w-full items-center">
              <!--            <mat-checkbox class="text-normal mat-caption" color="primary" formControlName="remember_me">Ricordami</mat-checkbox>-->
              <!--            <a routerLink="" class="mat-text-primary">Password dimenticata</a>-->
            </div>
          } @else {
            <div class="text-center text-lg">Controlla la tua casella di posta.<br>
              Ti abbiamo inviato un’e-mail per effettuare il login e partecipare al gioco online “Swiss Family Village”.<br>
              Rispondi il più velocemente possibile alle domande. A fine giornata chi ha risposto correttamente al
              maggior numero di domande e nel minor tempo riceverà un regalo.
            </div>
          }


        </form>
      </div>
    }

  </mat-card-content>
</mat-card>
