import {Component, Input} from '@angular/core';
import {exhaustMap, Observable, of, share, Subject, switchMap, takeWhile, tap, timer} from 'rxjs';
import {BaseRxjsComponent} from '../../shared/components/BaseRxjsComponent';
import {MatButton} from '@angular/material/button';
import {AsyncPipe} from '@angular/common';
import {MatProgressBar} from '@angular/material/progress-bar';
import {loading} from '../../shared/rxjs/loading';
import {loaded} from '../../shared/rxjs/loaded';
import {debug} from '../../shared/rxjs/debug';
import {AuthService} from '../auth.service';
import {map} from 'rxjs/operators';
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'nbd-resend-activation-email',
  standalone: true,
  imports: [
    MatButton,
    AsyncPipe,
    MatProgressBar,
    TranslateModule
  ],
  templateUrl: './resend-activation-email.component.html',
  styleUrl: './resend-activation-email.component.scss'
})
export class ResendActivationEmailComponent extends BaseRxjsComponent {

  resendActivationEmail$ = new Subject<void>();
  sentAndWait$: Observable<number | null>;
  error: string | null = null;

  @Input() email!: string;

  constructor(private auth: AuthService) {
    super();

    this.sentAndWait$ = this.resendActivationEmail$.pipe(
      tap(() => this.error = null),
      loading(this),
      exhaustMap(() => this.auth.resendActivationEmail(this.email)),
      switchMap((res) => {
        this.loaded();
        if (res) {
          const waitFor = 60;
          return timer(0, 1000).pipe(
            map((i) => waitFor - i),
            switchMap((i) => {
              return i > 0 ? of(i) : of(null);
            }),
            takeWhile((i) => i !== null, true)
          )
        } else {
          this.error = 'Impossibile inviare la mail di attivazione';
          return of(null);
        }

      }),
      debug('Resend activation email'),
      share()
    );

  }

}
