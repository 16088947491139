import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, Type} from '@angular/core';
import {FieldTypeConfig, FormlyConfig, FormlyFieldConfig} from '@ngx-formly/core';
import {FieldType, FormlyFieldProps} from '@ngx-formly/material/form-field';
import {DateTime} from 'luxon';
import {Subscription} from 'rxjs';


interface DatetimepickerProps extends FormlyFieldProps {
  datepickerOptions?: Partial<{
    touchUi: boolean;
    opened: boolean;
    disabled: boolean;
    startView: 'month' | 'year' | 'multi-year';
    datepickerTogglePosition: 'suffix' | 'prefix';
    filter: (date: any | null) => boolean;
    min: any;
    max: any;
    dateInput: (field: FieldTypeConfig<DatetimepickerProps>, event: any) => void;
    dateChange: (field: FieldTypeConfig<DatetimepickerProps>, event: any) => void;
    startAt: any | null;
  }>;
}

export interface FormlyInputFieldConfig extends FormlyFieldConfig<DatetimepickerProps> {
  type: 'datetimepicker' | Type<DatetimepickerTypeComponent>;
}

@Component({
  selector: 'nbd-datetimepicker-type',
  templateUrl: './datetimepicker-type.component.html',
  styleUrls: ['./datetimepicker-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatetimepickerTypeComponent extends FieldType<FieldTypeConfig<DatetimepickerProps>> implements OnInit, OnDestroy {

  private dateTimeLocalFormat = "y-MM-dd'T'T";
  private sub = new Subscription();


  constructor(private config: FormlyConfig, private cdRef: ChangeDetectorRef) {
    super();
    // if(!this.field.parsers) {
    //   this.field.parsers = [];
    // }
    // this.field.parsers.push((value: any) => this.fromISOToLocal(value))
  }

  ngOnInit() {
    if (!this.field.parsers) {
      this.field.parsers = [];
    }
    this.field.parsers.push(
      (value: any) => {
        if (value?.length === 25) {
          return this.fromISOToLocal(value)
        }
        return value;
      }
    )
    //Convert the initial value to datetime-local format
    if (this.formControl.value) {
      // console.log(`Setting ${this.formControl.value} to local`);
      this.formControl.setValue(this.fromISOToLocal(this.formControl.value), {emitEvent: false});
    }
    //
    // // Listen for changes and convert the value back to ISO format
    // this.sub.add(this.formControl.valueChanges.pipe(
    //   debug('DateTimePicker ValueChange'),
    //   pairwise(),
    //   debug('DateTimePicker ValueChange PAIRWISE'),
    //   tap(([prev, curr]) => {
    //     console.log(this.key, prev, curr);
    //     if (curr && (prev !== curr)) {
    //       // console.log(`Converting ${value} to local`);
    //       // console.log(res);
    //       // const res = DateTime.fromFormat(value, this.dateTimeLocalFormat).toISO();
    //       const res = this.fromISOToLocal(curr);
    //       if (res) {
    //         // this.model[this.key as string] = res;
    //         this.formControl.setValue(res);
    //       }
    //     }
    //   })
    // ).subscribe());

  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    // this.sub.unsubscribe();
  }

  fromISOToLocal(date: string) {
    return date ? DateTime.fromISO(date).toFormat(this.dateTimeLocalFormat) : null;
  }
}
