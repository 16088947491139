<div class="flex items-center space-x-2">
  @for (s of eventStatusBadges; track s.label) {
    <div
      [ngClass]="s.bgClass"
      class="status-badge flex items-center space-x-2 !font-normal !py-2">
      <mat-icon [ngClass]="s.class" class="!text-sm !w-4 !h-4 !leading-4">{{ s.icon }}</mat-icon>
      <div [ngClass]="s.class" class="!text-sm">{{ s.label }}</div>
    </div>
  }
</div>
