import {Observable, OperatorFunction} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

export function catchHttpErrorOrDefault<T, R>(defaultValue: R, errorHandler: (error: any) => Observable<boolean>): OperatorFunction<T, T | R> {
  return (source: Observable<T>) => source.pipe(
    catchError(err => errorHandler(err).pipe(
      map(() => defaultValue)
    ))
  );
}
