export const tempTranslations: {
  auth: { [key: string]: string },
  errors: { [key: string]: string },
  transactions: { [key: string]: string },
  system: { [key: string]: string },
  common: { [key: string]: string }
} = {
  auth: {
    "invalidCredentials": "Credenziali non valide, verifica di aver inserito correttamente Email e Password",
    "userInactive": "Account non attivo",
    "emailExists": "Questa email è già registrata",
    "tooManyAttempts": "Troppi tentativi falliti. Riprova tra qualche minuto.",
    "invalidCaptcha": "Captcha non valido"
  },
  errors: {
    "eventForbidden": "Impossibile accedere a questo evento",
    "invalidAccount": "Account non valido",
    "insufficientCredits": "Crediti insufficienti",
    "invalidEmail": "Indirizzo email non valido",
    "invalidRequest": "Richiesta non valida",
    "cannotDeleteEvent": "Impossibile eliminare questo evento",
    "eventAlreadyEnabled": "L'evento è già stasto attivato",
    "invalidQuery": "Query non valida",
    "invalidGuestId": "Guest id non valido",
    "userAlreadyCheckedIn": "L'utente ha già effettuato il check in",
    "invalidDates": "Date non valide",
    "noPlanSelected": "Non hai selezionato un pacchetto",
    "missingOrderId": "ID ordine mancante",
    "missingPayPalCredentials": "Credenziali PayPal mancanti",
    "eventNotEnabled": "Questo evento non è attivo",
    "eventExpired": "Questo evento è terminato",
    "invalidLink": "Link non valido",
    "missingParameters": "Parametri insufficienti",
    "invalidPromoCode": "Codice Promo non valido",
    "missingAccountInvoiceAddress": "Specificare almeno un dato tra SDI e PEC",
    "eventTooSoon": "Data e ora di inizio evento non possono essere precedenti alla data e ora corrente",
    "eventLimitReached_guests": "Hai raggiunto il massimo numero di Guest per questo evento",
    "invalidEventPlan": "Piano RealGuest non valido",
    "dataUnavailable": "I dati non sono ancora disponibili. Riprova più tardi.",
    "cannotEditDatesWithCheckin": "Non puoi modificare le date di un evento con check in effettuati"
  },
  transactions: {
    "enableEvent": "Attivazione evento",
    "promoCode": "Codice Promo",
    "newGuestCheckIn": "Nuovo Guest (Check In)",
    "newGuestPlatform": "Nuovi Guest (Gestionale)"
  },
  system: {
    "days": "giorni",
    "hours": "ore"
  },
  common: {
    "unknownError": "Errore sconosciuto"
  }
}
