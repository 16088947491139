<h1 mat-dialog-title>Seleziona un'immagine</h1>
<div class="mat-app-background" mat-dialog-content>
  @if (images$ | async; as images) {
    <div class="grid image-grid gap-4">
      @for (i of images; track i) {
        <nbd-image-gallery-item (selected)="selectedImage = $event"
          [image]="i"
          [isSelected]="i == selectedImage">
        </nbd-image-gallery-item>
      }
    </div>
  } @else {
    <nbd-loader [fill]="true"></nbd-loader>
  }
</div>
<div align="end" mat-dialog-actions>
  <button [disabled]="!selectedImage"
    [mat-dialog-close]="environment.assetsBucketUrl + '/' + selectedImage?.key"
    color="primary"
    mat-flat-button>
    OK
  </button>
  <button [mat-dialog-close]="null" mat-button>Annulla</button>
</div>
