export class LimitedStack<T> {
  private stack: T[];
  private readonly maxSize: number;

  constructor(maxSize: number) {
    this.maxSize = maxSize;
    this.stack = [];
  }

  push(item: T): void {
    if (this.stack.length >= this.maxSize) {
      this.stack.shift(); // Remove the oldest item
    }
    this.stack.push(item);
  }

  pop(): T | undefined {
    return this.stack.pop();
  }

  peek(): T | undefined {
    return this.stack[this.stack.length - 1];
  }

  size(): number {
    return this.stack.length;
  }

  isEmpty(): boolean {
    return this.stack.length === 0;
  }

  printStack(): void {
    console.log(this.stack);
  }
}
