import {AbstractControl, ValidationErrors, Validators} from '@angular/forms';
import {FormlyFieldConfig} from '@ngx-formly/core';

export abstract class EmailValidator {
  static validator(control: AbstractControl): ValidationErrors | null {
    return Validators.email(control);
  }

  static message(error: any, field: FormlyFieldConfig) {
    return `Indirizzo email non valido`;
  }
}
