<div [ngClass]="{
'w-full': fill,
'h-full': fill
}" class="grid place-items-center p-5">
  <div class="flex flex-col space-y-4">
    <mat-spinner [color]="color" mode="indeterminate"></mat-spinner>
    @if (label) {
      <div class="mat-caption">{{ label }}</div>
    }
  </div>

</div>
