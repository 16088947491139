<h1 mat-dialog-title>Salva filtro</h1>
<div mat-dialog-content>
  <p>Inserisci un nome per questo filtro:</p>
  <mat-form-field appearance="outline" class="w-full">
    <mat-label>Nome filtro</mat-label>
    <input [(ngModel)]="name" matInput>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button [mat-dialog-close]="null" mat-button>Annulla</button>
  <button [mat-dialog-close]="name" cdkFocusInitial color="primary" mat-flat-button>Ok</button>
</div>
