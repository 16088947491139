import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from "../auth.service";
import {PasswordValidator} from "../classes/PasswordValidators";
import {BaseRxjsComponent} from "../../shared/components/BaseRxjsComponent";
import {MustMatchValidator} from "../../formly-components/validators/MustMatchValidator";


@Component({
  selector: 'tr-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss']
})
export class NewPasswordComponent extends BaseRxjsComponent implements OnInit {

  success = false;

  newPwdForm = this.fb.nonNullable.group({
    password: ['', [
      Validators.required,
      Validators.minLength(8),
      PasswordValidator.hasNumber(),
      // PasswordValidator.hasSpecialChars(),
      PasswordValidator.hasUppercase(),
      PasswordValidator.hasLowercase(),
    ]],
    confirmPassword: ['', Validators.required]
  }, {
    validators: MustMatchValidator.mustMatch('password', 'confirmPassword')
  });

  hidePassword = true;
  hidePassword2 = true;

  error = false;
  errorMessage = '';
  private h: string = '';
  private t: string = '';

  constructor(private fb: FormBuilder, private as: AuthService, private route: ActivatedRoute, private router: Router) {
    super();
  }

  get f() {
    return this.newPwdForm.controls;
  }

  ngOnInit(): void {
    this.loading();
    this.newPwdForm.reset();
    this.newPwdForm.setErrors(null);
    this.route.queryParams.subscribe(params => {

      this.h = params['h'];
      this.t = params['t'];

      if (!this.h || !this.t) {
        this.loaded()
        this.router.navigate(['/auth/login']);
      } else {
        this.loaded()
      }
    });
  }

  changePassword() {
    // this.api.newPassword({})
    this.error = false;
    this.loading();
    this.as.newPassword(this.h, this.t, this.f.password.value).toPromise().then(
      (data) => {
        this.success = true;
        this.loaded()
      },
      (err) => {
        this.error = true;
        let message = 'auth.newPassword.failed';
        if (err.error?.description) {
          message = 'auth.newPassword.' + err.error.description;
        }
        this.errorMessage = message;
        this.loaded()
      }
    );

  }

}
