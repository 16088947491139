<nbd-login-register-container class="w-full h-full">
  <div class="flex py-12  md:py-0 items-center w-full h-full">
    @if (loading$ | async) {
      <div class="w-full h-full">
        <nbd-loader [fill]="true" color="primary"></nbd-loader>
      </div>
    } @else {
      <div class="mx-auto">
        <h2 class="text-2xl font-extrabold text-[#6a39db]">{{ "auth.login.title" | translate }}</h2>
        <form [formGroup]="loginForm" class="w-full max-w-xs space-y-4 mx-auto">
          <p class="text-center">{{ "auth.login.just-loggedin" | translate}}
            <a class="mat-text-primary" routerLink="/auth/register">{{"common.register" | translate}}.</a>
          </p>
          <mat-form-field appearance="outline" class="w-full">
            <mat-label>{{"common.email" | translate}}</mat-label>
            <input autocomplete="username" formControlName="email" matInput type="email">
            @if (loginForm.controls.email.errors?.['required']) {
              <mat-error>
                {{"common.errors.email.enter" | translate}}
              </mat-error>
            }
            @if (loginForm.controls.email.errors?.['email']) {
              <mat-error>
                {{"common.errors.email.invalid" | translate}}
              </mat-error>
            }
          </mat-form-field>
          <mat-form-field appearance="outline" class="w-full">
            <mat-label>{{"common.password" | translate}}</mat-label>
            <input [type]="hidePassword ? 'password' : 'text'" autocomplete="current-password" formControlName="password"
              matInput>
              <button (click)="togglePasswordVisibility()" [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hidePassword"
                mat-icon-button
                matSuffix
                tabindex="-1">
                <mat-icon>{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
              </button>
              @if (loginForm.controls.password.errors?.['required']) {
                <mat-error>
                  {{ "common.errors.password.required" | translate }}
                </mat-error>
              }
            </mat-form-field>
            @if (error$ | async; as error) {
              @if (error.length > 0) {
                <div class="mat-bg-warn text-white text-center rounded p-2">
                  {{ error }}
                </div>
              }
            }
            @if (showResendActivation$ | async; as resendActivationData) {
              <nbd-resend-activation-email [email]="resendActivationData.email"></nbd-resend-activation-email>
            }
            <button (click)="login$.next()"
              [disabled]="loginForm.invalid"
              class="w-full"
              color="primary"
              mat-raised-button>
              {{ "common.login" | translate }}
            </button>
            <div class="flex justify-end w-full items-center">
              <!--            <mat-checkbox class="text-normal mat-caption" color="primary" formControlName="remember_me">Ricordami</mat-checkbox>-->
              <a class="mat-text-primary" routerLink="/auth/forgot-password">{{ "auth.login.remember-password" | translate }}</a>
            </div>
          </form>
        </div>
      }
      </div>
    </nbd-login-register-container>
