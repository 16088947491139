import {Component} from '@angular/core';
import {EventsService} from '../../realguest/services/events.service';
import {Observable, of, switchMap} from 'rxjs';
import {RgEvent} from '../../realguest/classes/rg-event';
import {map} from 'rxjs/operators';
import {SelectedEventComponent} from '../selected-event/selected-event.component';
import {AsyncPipe} from '@angular/common';

@Component({
  standalone: true,
  selector: 'nbd-toolbar',
  templateUrl: './toolbar.component.html',
  imports: [
    SelectedEventComponent,
    AsyncPipe
  ],
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent {

  event$: Observable<RgEvent | null>;

  constructor(public es: EventsService) {
    this.event$ = this.es.selectedEvent$.pipe(
      switchMap((event) => {
        if (!!event) {
          return this.es.timer$.pipe(
            map(() => this.es.setEventViewData(event))
          )
        } else {
          return of(null);
        }
      })
    )
  }

}
