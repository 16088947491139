<div class="bg-gray-200 rounded p-2 text-start text-xs">
  <p class="">{{'auth.resend-activation.title' | translate}}</p>
  @if(error) {
    <p class="mat-text-warn font-bold text-center">
      {{error}}
    </p>
  }

  @if(loading$ | async) {
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  } @else {
    @if(!(sentAndWait$ | async)) {
      <div class="text-center" >
        <div class="mb-2">{{'auth.resend-activation.message-send' | translate}}</div>
        <button mat-flat-button color="primary" class="ml-auto" (click)="resendActivationEmail$.next()">{{'auth.resend-activation.action-send' | translate}}</button>
      </div>
    }
  }

  @if(sentAndWait$ | async; as timeLeft) {
    @if(timeLeft) {
      <div class="text-center" >
        <div class="mb-2">{{'auth.resend-activation.title-sent' | translate}}</div>
        <div>{{'auth.resend-activation.message-sent-1' | translate}}{{timeLeft}}{{'auth.resend-activation.message-sent-2' | translate}}</div>
      </div>
    }
  }

</div>
