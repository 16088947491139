import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'nbd-json-viewer-dialog',
  templateUrl: './json-viewer-dialog.component.html',
  styleUrls: ['./json-viewer-dialog.component.scss']
})
export class JsonViewerDialogComponent {

  protected readonly JSON = JSON;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { title: string, json: string }) {
  }
}
