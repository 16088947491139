import {Component, Input} from '@angular/core';
import {MenuItem} from '../classes/menu-item';
import {Router} from '@angular/router';
import {MatDrawer} from '@angular/material/sidenav';

@Component({
  selector: 'nbd-nav-menu-item',
  templateUrl: './nav-menu-item.component.html',
  styleUrls: ['./nav-menu-item.component.scss']
})
export class NavMenuItemComponent {

  @Input() m?: MenuItem;
  @Input() collapsed: boolean = false;
  @Input() isHandset: boolean = false;
  @Input() drawer?: MatDrawer;

  constructor(private router: Router) {
  }

  handleClick(event: MouseEvent) {
    if (this.m) {

      if (this.m.onClick) {
        this.m.onClick();
      }

      if (!this.m.isActive || !this.m.children?.length) {
        event.preventDefault();
        event.stopPropagation();
      }

      if (this.isHandset) {
        this.drawer?.close();
      }

      this.router.navigate([this.m.url]);

    }
  }

}
