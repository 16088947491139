@if (url) {
  <div class="flex space-x-4 items-center">
    <button (click)="clipboard.copy(url)" mat-icon-button matTooltip="Copia link">
      <mat-icon>content_copy</mat-icon>
    </button>
    <button (click)="share()" mat-icon-button matTooltip="Condividi link">
      <mat-icon>share</mat-icon>
    </button>
  </div>
}
