<section class="w-full h-full relative">
  <div class="grid xl:grid-cols-2 grid-cols-1 h-full">
    <div class="relative flex h-full items-center">
      <div class="md:my-12 text-white text-center xl:py-60 py-10 2xl:px-40 md:px-20 px-7 z-30 w-full">
        <div class="xl:w-1/2 w-fit px-6 xl:px-0 mx-auto max-w-[350px]">
          <img alt="" class="py-4" src="{{environment.assetsBucketUrl}}/img/logo_white.png">
        </div>
        <h2 class="hidden xl:block sm:text-2xl !text-3xl font-semibold uppercase text-white mt-5">{{'auth.login-register-container.title' | translate}}</h2>
        <p class="hidden xl:block text-base font-black text-white leading-7 my-10" [innerHTML]="'auth.login-register-container.text-1' | translate"></p>
        <p class="hidden xl:block text-base font-normal text-white leading-7 my-10" [innerHTML]="'auth.login-register-container.text-2' | translate"></p>
        <div class="flex justify-center mt-0 md:mt-2 xl:mt-8 pb-px">
          <p class="my-5 text-slate-200">{{'auth.login-register-container.copyright' | translate}}</p>
        </div>
      </div>

      <div class="overflow-hidden">
        <div
          class="absolute inset-0 w-full h-full bg-gradient-to-r from-[#b3497a] from-20%  to-[#6a39db] to-90% "></div>
      </div>
    </div>
    <div class="text-center bg-white w-full h-full flex items-center justify-center">
      <ng-content></ng-content>
    </div>
  </div>
</section>
