import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {ColumnType} from '../models/ColumnType';
import {ColumnConfig} from '../models/ColumnConfig';
import {MatDialog} from '@angular/material/dialog';
import {JsonViewerDialogComponent} from '../json-viewer-dialog/json-viewer-dialog.component';

@Component({
  selector: 'nbd-crm-item-renderer',
  templateUrl: './crm-item-renderer.component.html',
  styleUrls: ['./crm-item-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CrmItemRendererComponent implements OnInit {

  @Input() rowData?: any;
  @Input() item?: any;
  @Input() columnConfig!: ColumnConfig;

  protected readonly columnType = ColumnType;
  protected readonly Boolean = Boolean;
  protected readonly JSON = JSON;
  protected readonly Array = Array;

  protected parsedItem?: string[] | string;

  constructor(private dialog: MatDialog) {

  }

  ngOnInit(): void {
    if (this.columnConfig.type === ColumnType.JSONArray) {
      try {
        this.parsedItem = JSON.parse(this.item);
      } catch {
        this.parsedItem = this.item;
      }
    }
  }

  showJsonDialog(item: { title: string, json: string }) {
    this.dialog.open(JsonViewerDialogComponent, {
      maxWidth: '900px',
      width: '100%',
      data: item
    });
  }


}
