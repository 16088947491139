import {AbstractControl, ValidationErrors} from '@angular/forms';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {PhoneNumber} from '../../realguest/classes/phone-number';
import {PhoneInputProps} from '../phone-type/phone-type.component';

export abstract class PhoneValidator {

  protected static phoneUtil = new PhoneNumber();

  static validator(control: AbstractControl,
                   field: FormlyFieldConfig<PhoneInputProps>,
                   options = {},): ValidationErrors | null {
    if(field.props?.telInput) {
      return PhoneValidator.phoneUtil.checkNumber(field.props?.telInput?.getNumber() ?? '').valid ? null : {phone: true};
    }
    return null;
  }

  static message(error: any, field: FormlyFieldConfig) {
    return `Numero di telefono non valido`;
  }
}
