import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {AuthRoutingModule} from './auth-routing.module';
import {MatButtonModule} from '@angular/material/button';
import {LoginComponent} from './login/login.component';
import {AuthFlowComponent} from './auth-flow/auth-flow.component';
import {MatCardModule} from '@angular/material/card';
import {ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {SharedModule} from '../shared/shared.module';
import {UserMenuComponent} from './user-menu/user-menu.component';
import {MatMenuModule} from '@angular/material/menu';
import {MatListModule} from '@angular/material/list';
import {RegisterComponent} from './register/register.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {UrlLoginComponent} from './url-login/url-login.component';
import {RequestUrlLoginComponent} from './request-url-login/request-url-login.component';
import {MatStepperModule} from "@angular/material/stepper";
import {EmailConfirmedComponent} from './email-confirmed/email-confirmed.component';
import {ForgotPasswordComponent} from "./forgot-password/forgot-password.component";
import {NewPasswordComponent} from "./new-password/new-password.component";
import {LoginRegisterContainerComponent} from './login-register-container/login-register-container.component';
import {ResendActivationEmailComponent} from './resend-activation-email/resend-activation-email.component';

@NgModule({
  declarations: [
    LoginComponent,
    AuthFlowComponent,
    UserMenuComponent,
    RegisterComponent,
    UrlLoginComponent,
    RequestUrlLoginComponent,
    EmailConfirmedComponent,
    ForgotPasswordComponent,
    NewPasswordComponent,
    LoginRegisterContainerComponent
  ],
  exports: [
    LoginComponent,
    AuthFlowComponent,
    UserMenuComponent
  ],
    imports: [
        CommonModule,
        AuthRoutingModule,
        MatButtonModule,
        MatCardModule,
        ReactiveFormsModule,
        MatInputModule,
        MatIconModule,
        MatCheckboxModule,
        SharedModule,
        MatMenuModule,
        MatListModule,
        MatDatepickerModule,
        MatStepperModule,
        ResendActivationEmailComponent
    ]
})
export class AuthModule {
}
