import {Component, Input} from '@angular/core';
import {RgEvent} from '../../realguest/classes/rg-event';
import {EventStatus} from '../../events/classes/event-status';
import {NgClass} from '@angular/common';
import {MatIcon} from '@angular/material/icon';
import {tempTranslations} from '../../realguest/classes/temp-translations';

@Component({
  standalone: true,
  selector: 'nbd-event-status-badge',
  templateUrl: './event-status-badge.component.html',
  imports: [
    NgClass,
    MatIcon
  ],
  styleUrls: ['./event-status-badge.component.scss']
})
export class EventStatusBadgeComponent {

  eventStatusBadges: StatusBadgeConfig[] = [];

  eventStatusCfg = {
    [EventStatus.Enabled]: {
      label: "Evento attivato",
      icon: "task_alt",
      bgClass: "mat-bg-success",
    },
    [EventStatus.NotEnabled]: {
      label: "Non hai attivato l'evento!",
      icon: "warning",
      bgClass: "mat-bg-warning",
    },
    [EventStatus.Running]: {
      label: "Evento in corso",
      icon: "calendar_today",
      bgClass: "mat-bg-accent",
      class: "mat-text-accent-contrast",
    },
    [EventStatus.Finished]: {
      label: "Evento concluso",
      icon: "event_busy",
      bgClass: "mat-bg-warn",
    },
    [EventStatus.StartingSoon]: {
      label: "Mancano {{amount}} {{unit}} all'evento",
      icon: "schedule",
      class: "mat-text-primary",
      bgClass: "!bg-white"
    }
  };

  @Input() set event(event: RgEvent | null) {
    this.setEventBadges(event);
  }

  private setEventBadges(event: RgEvent | null) {
    this.eventStatusBadges = [];

    if (!event) {
      return;
    }

    // if (event.enabled) {
    //   this.eventStatusBadges.push(this.eventStatusCfg[EventStatus.Enabled]);
    // }

    if (event.running && !event.enabled) {
      this.eventStatusBadges.push(this.eventStatusCfg[EventStatus.NotEnabled]);
    }


    if (event.timeToEvent && event.timeToEvent.amount > 0) {
      this.eventStatusBadges.push({
        ...this.eventStatusCfg[EventStatus.StartingSoon],
        label: this.eventStatusCfg[EventStatus.StartingSoon].label
          .replaceAll("{{amount}}", Math.floor(event.timeToEvent.amount).toString())
          .replaceAll("{{unit}}", tempTranslations.system[event.timeToEvent.unit])
      });
      return;
    }

    if (event.running) {
      this.eventStatusBadges.push(this.eventStatusCfg[EventStatus.Running]);
      return;
    }

    if (event.finished) {
      this.eventStatusBadges.push(this.eventStatusCfg[EventStatus.Finished]);
      return;
    }
  }

}

export interface StatusBadgeConfig {
  label: string;
  icon?: string;
  class?: string;
  bgClass?: string;
}
